import coverImg from "../../assets/images/cover.png";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import { Divider, Form, Input, Button } from "antd";
import { auth } from "../../api/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import "./ForgotPassword.styles.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [successPage, setSuccessPage] = useState(false);
  const email = form.getFieldValue("email");
  const sendResetEmail = async (values) => {
    try {
      await sendPasswordResetEmail(auth, values?.email);
      setSuccessPage(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="container">
      <div className="image-container">
        <img className="cover-image" src={coverImg} alt="cover" />
      </div>
      <Divider className="vd divider-color" type="vertical" />
      <div className="form-container forgot">
        <img
          src={logo}
          className="logo"
          alt="logo"
          width="200px"
          height="40px"
        />
        {!successPage ? (
          <Form
            className="forgot-form"
            form={form}
            layout="vertical"
            onFinish={sendResetEmail}
          >
            <p className="form-title">Forgot your password?</p>
            <Form.Item
              label="Email ID of existing account"
              name="email"
              required={false}
              rules={[{ required: true, message: "Please enter email id" }]}
            >
              <Input placeholder="Enter your email ID" />
            </Form.Item>
            <Button
              type="primary"
              className="marginAuto"
              htmlType="submit"
              shape="round"
            >
              Send Reset Link
            </Button>
          </Form>
        ) : (
          <div className="success-page">
            <p className="content">
              Information on how to reset your password has been sent to:{" "}
              {email}
            </p>
            <Link to={"/login"}>
              <Button
                type="primary"
                className="marginAuto"
                shape="round"
                style={{ marginBottom: "10px" }}
              >
                Back to Login
              </Button>
            </Link>
            <Button
              className="marginAuto"
              shape="round"
              onClick={() => setSuccessPage(false)}
            >
              Change email ID
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
