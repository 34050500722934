import { Drawer } from "antd";
import { ArrowRightOutlined, BellFilled } from "@ant-design/icons";
import NotificationItem from "./NotificationItem/NotificationItem.component";
import api from "../../api/api";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import "./Notification.styles.scss";
import { useState } from "react";

const Notification = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData("currentUser");
  const [notificationVisible, setNotificationVisible] = useState(false);

  const notifications = currentUser?.notifications;

  const unreadNotifications = notifications?.filter(
    (notification) => !notification.read_receipt
  );

  const handleNotificationClick = async (notification) => {
    if (notification?.click_action === "new_assignment") {
      navigate(
        `/user/${currentUser?.crew_id}/hub/${currentUser?.hub?.hub?.hub_id}`
      );
    } else {
      navigate(`/user/${currentUser?.crew_id}/hub/invite-request-management`);
    }
    await api.putNotification(notification?.notification_id);
    queryClient.invalidateQueries("currentUser");
    setNotificationVisible(false);
  };

  const notificationItems =
    unreadNotifications?.length === 0 ? (
      <p style={{ textAlign: "center", color: "white" }}>No notifications</p>
    ) : (
      unreadNotifications?.map((notification) => (
        <NotificationItem
          notification={notification}
          key={notification.notification_id}
          onClick={() => handleNotificationClick(notification)}
        />
      ))
    );

  return (
    <>
      <div className="notificationIcon-container">
        <BellFilled onClick={() => setNotificationVisible(true)} />
        {unreadNotifications?.length ? (
          <span className="notification-count">
            {unreadNotifications?.length}
          </span>
        ) : null}
        <Drawer
          rootClassName="root-notification-drawer"
          className="drawer-right notification-drawer"
          placement="right"
          onClose={(event) => {
            event.stopPropagation();
            setNotificationVisible(false);
          }}
          open={notificationVisible}
          closeIcon={<ArrowRightOutlined />}
        >
          {notificationItems}
        </Drawer>
      </div>
    </>
  );
};
export default Notification;
