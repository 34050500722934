import { Button, Checkbox, Form, Input, Spin, message } from "antd";
import api from "../../../api/api";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import appleImg from "../../../assets/images/SVG/apple-white.svg";
import googleImg from "../../../assets/images/SVG/google-logo.svg";
import { UserAuth } from "../../../context/AuthContext";
import { useState } from "react";
import { auth } from "../../../api/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./LoginForm.styles.scss";
import { Link } from "react-router-dom";
import { handleRegistration } from "../RegisterForm/RegisterForm";

export const handleLogin = async (values, type) => {
  if (type === "email") {
    values.login_type = "Email";
  } else if (type === "apple") {
    values.login_type = "Apple";
  } else if (type === "google") {
    values.login_type = "Google";
  }
  try {
    const response = await api.loginUser(values, type);
    console.log(response);
    return { success: true };
  } catch (error) {
    console.error(error);
    message.error(error.message);
    return { success: false, error: error?.message };
  }
};

const LoginForm = () => {
  const [form] = Form.useForm();
  const { googleSignIn, appleSignIn } = UserAuth();
  const navigate = useNavigate();
  const [loadingGoogleSignIn, setLoadingGoogleSignIn] = useState(false);

  const queryClient = useQueryClient();

  const handleSignUpButtonClick = () => {
    navigate("/register");
  };

  const loginMutation = useMutation((values) => handleLogin(values, "email"), {
    onSuccess: async () => {
      console.log("Login successful!");
      // Fetch current user details
      const currentUserResponse = await api.getCurrentUser();
      console.log(currentUserResponse);

      if (currentUserResponse) {
        // Redirect to UserHome
        queryClient.setQueryData("currentUser", currentUserResponse);
        if (currentUserResponse?.hub) {
          navigate(`/user/${currentUserResponse.crew_id}`);
        } else {
          navigate(`/user/${currentUserResponse.crew_id}/hub`);
        }
      }
    },
    onError: (error) => {
      console.error("Login error:", error);
      const errorMessage = error.message;
      console.log(errorMessage);
      message.error(errorMessage || "An error occurred during login");
    },
  });

  const onFinish = async (values) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;
      const submitData = { uid: user.uid };
      await loginMutation.mutateAsync(submitData);
    } catch (error) {
      console.error("Login error:", error);
      message.error(error.message || "Invalid username or password");
    }
  };

  const socialLoginMutation = useMutation(
    (values) => handleLogin(values, values.type),
    {
      onSuccess: async () => {
        console.log("Google Login successful!");
        // Fetch current user details
        const currentUserResponse = await api.getCurrentUser();
        console.log(currentUserResponse);

        if (currentUserResponse) {
          // Redirect to UserHome
          queryClient.setQueryData("currentUser", currentUserResponse);
          if (currentUserResponse?.hub) {
            navigate(`/user/${currentUserResponse.crew_id}`);
          } else {
            navigate(`/user/${currentUserResponse.crew_id}/hub`);
          }
        }
      },
      onError: (error) => {
        console.error("Google Login error:", error);
        const errorMessage = error.message;
        console.log(errorMessage);
        message.error(errorMessage || "An error occurred during Google login");
      },
    }
  );
  const handleGoogleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await googleSignIn();
      console.log({ userData });
      const email = userData?.user?.email;
      const profilePicture = userData?._tokenResponse?.photoUrl;
      const splitName = userData?._tokenResponse?.displayName?.split(" ");
      const firstName = splitName[0];
      const lastName = splitName.slice(1).join(" ");
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;
      const loginValues = {
        uid: uid,
        login_type: "Google",
      };
      const registerValues = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
        profile_picture_url: profilePicture,
      };
      const loginResult = await socialLoginMutation.mutateAsync(loginValues);
      if (loginResult?.success === false) {
        await handleRegistration(registerValues, "google");
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  const handleAppleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await appleSignIn();
      console.log({ userData });
      const email = userData?._tokenResponse?.email;
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;

      let firstName = "";
      let lastName = "";

      if (userData?.user?.displayName) {
        const splitName =
          userData?.user?.displayName?.trim().split(/\s+/) || [];
        firstName = splitName[0] || "";
        lastName = splitName?.slice(1).join(" ") || "";
      } else {
        const emailNamePart = email.split("@")[0];
        const nameLength = emailNamePart?.length;
        const halfLength = Math.ceil(nameLength / 2);
        firstName = emailNamePart?.substring(0, halfLength);
        lastName = emailNamePart?.substring(halfLength);
      }

      const values = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
      };

      const loginValues = {
        uid: uid,
        login_type: "Apple",
      };
      const loginResult = await socialLoginMutation.mutateAsync(loginValues);
      if (loginResult?.success === false) {
        await handleRegistration(values, "apple");
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  return (
    <div className="login-form">
      {loadingGoogleSignIn ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            required={false}
            rules={[{ required: true, message: "Please enter email id" }]}
          >
            <Input placeholder="Enter your email ID" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter your password" />
          </Form.Item>
          <Form.Item className="checkbox_forgot-container">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link className="login-form-forgot" to="/forgot-password">
              Forgot password?
            </Link>
          </Form.Item>
          <Button
            type="primary"
            className="marginAuto"
            htmlType="submit"
            shape="round"
            loading={loginMutation.isLoading}
          >
            Sign in
          </Button>
          <p style={{ textAlign: "center" }}>or</p>
          <div className="social-buttons">
            <Button className="social-button" onClick={handleAppleButtonClick}>
              <img src={appleImg} alt="apple" width="33px" height="33px" />
            </Button>
            <Button className="social-button" onClick={handleGoogleButtonClick}>
              <img src={googleImg} alt="google" width="25px" height="25px" />
            </Button>
          </div>
          <p style={{ color: "white", textAlign: "center" }}>No account yet?</p>
          <Button shape="round" onClick={handleSignUpButtonClick}>
            Sign up
          </Button>
        </Form>
      )}
    </div>
  );
};

export default LoginForm;
