// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import api from "./api";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCa7aSa8FA2d-s6Ro5L5Qgk-Y4rOsIHygQ",
  authDomain: "auth.cohubitate.com",
  projectId: "cohub-e4efc",
  storageBucket: "cohub-e4efc.appspot.com",
  messagingSenderId: "26800914039",
  appId: "1:26800914039:web:fb3f57193e608c244b5072",
  measurementId: "G-EQ66EF14JG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const sendTokenToServer = async (token) => {
  try {
    const response = await api.sendFCMToken(token); // Use your API function to send the token
    console.log(response);
    console.log("FCM Token sent to server successfully");
  } catch (error) {
    console.error("Error sending FCM token to server:", error);
  }
};

export const auth = getAuth(app);

export const getTokens = async (setTokenFound) => {
  //VAPID_KEY is the Web push certificates key pair
  return getToken(messaging, {
    vapidKey: `BINBd_Bz_Wqumfri06UGParjRPTJ1wDJGGAUNbBDvt1l2oPCG-IM3uVPrfKbq9yJhRApKsdI7V6J3zyIp5Oc0nk`,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        sendTokenToServer(currentToken);
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

// Listen for messages from the service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.action === "navigate") {
      const targetUrl = event.data.url;
      console.log(`Navigating to: ${targetUrl}`);
      window.location.href = targetUrl;
    }
  });
}
