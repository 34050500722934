import { Avatar, Button, Card, Modal } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import hubIcon from "../../assets/images/SVG/hub_icon.svg";
import "./InviteRequestCard.styles.scss";
import api from "../../api/api";
import { useQueryClient } from "react-query";

const ReceivedInviteRequestCard = ({ inviteRequest, loading }) => {
  const { confirm } = Modal;
  const queryClient = useQueryClient();
  console.log(inviteRequest);
  const toCamelCase = (str) => {
    return str?.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const senderFullNameCamelCase = toCamelCase(
    inviteRequest?.no_hub_crew_info?.full_name
  );
  const hubNameCamelCase = toCamelCase(inviteRequest?.hub_info?.hub_name);

  const handleClickConfirmation = async (response) => {
    const id = inviteRequest?.invitationrequest_id;
    const requestBody = {
      response: response === "accept" ? "accept" : "decline",
    };
    try {
      const apiResponse = await api.confirmInvitationRequest(id, requestBody);
      if (apiResponse?.status === 200) {
        queryClient.invalidateQueries("invitationRequestList");
        queryClient.invalidateQueries("currentUser");
        queryClient.invalidateQueries([
          "hubDetails",
          inviteRequest?.target_hub,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showConfirm = (response) => {
    confirm({
      title: "Confirmation",
      content: `Are you sure that you want to ${response} this ${
        inviteRequest?.type === "invitation" ? "invite" : "request"
      } ?`,
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },

      onOk() {
        handleClickConfirmation(response);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Card className="inviteRequest-card" loading={loading}>
      {inviteRequest?.type === "request" ? (
        <Avatar size={56} className="marginSide" src={profileAvatar} />
      ) : (
        <img
          className="marginSide"
          height="70px"
          width="70px"
          src={hubIcon}
          alt="hub-icon"
        />
      )}
      <div className="content-container">
        <p className="content">
          {inviteRequest?.type === "invitation"
            ? `You have been invited to hub ${hubNameCamelCase}`
            : `${senderFullNameCamelCase} has requested to join your ${hubNameCamelCase}`}
        </p>
        <div className="confirm-buttons">
          <Button
            shape="round"
            className="confirm-button btn-requested"
            onClick={() => showConfirm("decline")}
          >
            Decline
          </Button>
          <Button
            shape="round"
            className="confirm-button btn-blue"
            onClick={() => showConfirm("accept")}
          >
            Accept
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ReceivedInviteRequestCard;
