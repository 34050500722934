import { Button, Form, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./Support.scss";
import { useState } from "react";
import api from "../../api/api";

const Support = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt200KB = file.size / 1024 < 200;
    if (!isLt200KB) {
      message.error("Image must be smaller than 200KB!");
    }
    return isJpgOrPng && isLt200KB;
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === "done" || file.status === "uploading") {
      setFileList(
        fileList.map((file) => {
          if (file.originFileObj) {
            return {
              ...file,
              url: URL.createObjectURL(file.originFileObj),
            };
          }
          return file;
        })
      );
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("images", file.originFileObj);
      });
      formData.append("email", values.email);
      formData.append("description", values.description);

      const response = await api.support(formData);
      if (response?.status === 201) {
        message.success("Support request submitted successfully!");
      } else {
        message.error("Failed to submit support request.");
      }
    } catch (error) {
      message.error("Failed to submit support request.");
    }
    form.resetFields();
    setFileList([]);
    setLoading(false);
  };

  return (
    <div className="support-page">
      <h1 className="page-heading">Support Page</h1>
      <Form
        form={form}
        name="basic"
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input placeholder="Enter your email here" />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            { required: true, message: "Please write a feedback" },
            {
              max: 100,
              message: "Feedback must be less than 100 characters !",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Please provide a detailed description of the issue you are experiencing"
            autoSize={{
              minRows: 3,
            }}
          />
        </Form.Item>
        <Form.Item
          getValueFromEvent={(event) => {
            console.log({ event });
            return event?.fileList;
          }}
          name="images"
        >
          <Upload
            accept=".jpeg, .jpg, .png"
            listType="picture"
            maxCount={5}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            fileList={fileList}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
            onRemove={(file) => {
              setFileList(fileList.filter((item) => item.uid !== file.uid));
            }}
          >
            <Button className="upload-btn" icon={<UploadOutlined />}>
              <span className="upload">Click to upload (Max: 5)</span>
              <span className="upload-condition">PNG or JPG (max: 200kb)</span>
            </Button>
          </Upload>
        </Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Support;
