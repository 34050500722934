import React, { useEffect, useRef } from "react";
import { Spin } from "antd";
import { useMapLoader } from "../../context/MapContext";

const MyGoogleMap = ({ position, mapContainerStyle, center, mapClassName }) => {
  const { isLoaded } = useMapLoader();

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const mapInstance = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 12,
        mapId: "77e046c87bb0356f",
      });

      if (position) {
        if (markerRef.current === null) {
          // Create a new AdvancedMarkerElement if it doesn't exist
          markerRef.current =
            new window.google.maps.marker.AdvancedMarkerElement({
              position: { lat: position.latitude, lng: position.longitude },
              map: mapInstance,
            });
        } else {
          // Update the position of the existing AdvancedMarkerElement
          markerRef.current.position = {
            lat: position.latitude,
            lng: position.longitude,
          };
          markerRef.current.map = mapInstance; // Ensure the marker is associated with the map
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, position]);

  return !isLoaded ? (
    <Spin />
  ) : (
    <div className={mapClassName} ref={mapRef} style={mapContainerStyle}></div>
  );
};

export default MyGoogleMap;
