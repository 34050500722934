import { Card, Spin } from "antd";
import ProfileForm from "../Forms/ProfileForm/ProfileForm.component";
import "./EditProfile.styles.scss";
import { useQuery } from "react-query";
import api from "../../api/api";
import { useLocation } from "react-router";

const EditProfile = () => {
  const location = useLocation();
  const isEditProfilePage = location.pathname.includes("edit-profile");
  const queryKey = isEditProfilePage ? "formChoices" : "hubformChoices";

  const { data: radioOptions, isLoading } = useQuery(queryKey, async () => {
    if (isEditProfilePage) {
      return await api.getFormChoices();
    } else {
      return await api.getHubFormChoices();
    }
  });

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="editProfile-container">
      <Card className="editUser-card">
        <ProfileForm radioOptions={radioOptions} />
      </Card>
    </div>
  );
};

export default EditProfile;
