import api from "../../api/api";
import { useQuery, useQueryClient } from "react-query";
import "./InviteRequestViewer.styles.scss";
import { Card, Divider, Spin } from "antd";
import ReceivedInviteRequestCard from "../InviteRequestCard/ReceivedInviteRequestCard.component";
import SendInviteRequestCard from "../InviteRequestCard/SendInviteRequestCard.component";

const InviteRequestViewer = () => {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData("currentUser");
  const isUserAdmin = currentUser?.hub?.is_admin;

  // Use useQuery to fetch data
  const {
    data: invitationRequestList,
    isError,
    isLoading,
  } = useQuery("invitationRequestList", api.viewInvitationRequest);

  console.log(invitationRequestList);

  // Filter requests or invites based on user role
  const receivedInvitationRequests = isUserAdmin
    ? invitationRequestList?.requests.filter(
        (request) => request?.status === "requested"
      )
    : invitationRequestList?.invitations.filter(
        (invitation) => invitation?.status === "invited"
      );
  console.log(receivedInvitationRequests); //

  const sendInvitationRequests = isUserAdmin
    ? invitationRequestList?.invitations.filter(
        (invitation) => invitation?.status === "invited"
      )
    : invitationRequestList?.requests.filter(
        (request) => request?.status === "requested"
      );
  console.log(sendInvitationRequests);

  return (
    <>
      {isError ? (
        <h1 style={{ textAlign: "center", color: "white" }}>
          Error fetching invites/requests
        </h1>
      ) : (
        <div className="inviteRequestViewer-container">
          <h1 style={{ color: "white", textAlign: "center" }}>
            Invites & Requests
          </h1>

          {isLoading ? (
            <Spin className="spin" size="large" />
          ) : receivedInvitationRequests?.length === 0 &&
            sendInvitationRequests?.length === 0 ? (
            <div className="inviteRequests-container">
              <Card className="no-content-card">
                <p style={{ margin: "0" }}>No invites/requests available</p>
              </Card>
            </div>
          ) : (
            <>
              <div className="inviteRequests-container">
                {receivedInvitationRequests?.map((inviteRequest) => (
                  <>
                    <Divider>{isUserAdmin ? "Requests" : "Invites"}</Divider>
                    <ReceivedInviteRequestCard
                      key={inviteRequest?.invitationrequest_id}
                      inviteRequest={inviteRequest}
                      loading={isLoading}
                    />
                  </>
                ))}
                {sendInvitationRequests?.map((inviteRequest) => (
                  <>
                    <Divider>{isUserAdmin ? "Invites" : "Requests"}</Divider>
                    <SendInviteRequestCard
                      key={inviteRequest?.invitationrequest_id}
                      inviteRequest={inviteRequest}
                      loading={isLoading}
                    />
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default InviteRequestViewer;
