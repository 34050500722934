const Privacy = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1 style={{ color: "white" }}>Privacy Policy</h1>
      <p>Details about our privacy policy.</p>
    </div>
  );
};

export default Privacy;
