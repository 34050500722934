import HubImageUpload from "../HubImageUpload";
import {
  AddInputs,
  CustomRadioGroup,
  CustomSlider,
  FormInput,
  MultiSelectOptions,
} from "../ProfileFormInputContent";

export default function useHubFormConfig({
  form,
  formValues,
  radioOptions,
  //   profileDetails,
  fileList,
  setFileList,
}) {
  const sliderOptions = {
    no_of_bedrooms: {
      0: "0",
      7: "7",
      15: "15",
    },
    no_of_bathrooms: {
      0: "0",
      3: "3",
      6: "6",
    },
    maximum_crew: {
      0: "0",
      7: "7",
      15: "15",
    },
  };

  function getOptions(optionType) {
    const optionList = radioOptions?.[optionType]?.map((item) => item);
    return optionList;
  }

  function getMultiOptions(optionType) {
    const optionList = radioOptions?.[optionType]?.map((item) => {
      return {
        value: item.value,
        label: item.display, // Convert display to label
      };
    });
    return optionList;
  }

  function setDefaultValue(optionType, index) {
    const optionList = radioOptions?.[optionType]?.map((item) => item);
    const defaultValue = optionList?.[index]?.value;
    return defaultValue;
  }

  //   function getInitialValue(optionType) {
  //     const item = profileDetails?.profile_details?.find(
  //       (item) => item[optionType]
  //     );
  //     const initialValue = item?.[optionType]?.choice;
  //     return initialValue;
  //   }

  function getInputOnchange(e, name) {
    form?.setFieldValue(name, e.target.value);
  }

  function getMultiInputOnchange(checkedValues, name) {
    form?.setFieldValue(name, checkedValues);
  }

  const commonRadioProps = { onChange: getInputOnchange, form };

  return [
    {
      title: "Basic Information",
      formItems: [
        {
          input_heading: "Hub Pictures",
          component: (
            <HubImageUpload
              fileList={fileList}
              setFileList={setFileList}
              form={form}
            />
          ),
        },
        {
          input_heading: "Where is your shared home located?",
          component: (
            <div>
              <FormInput
                label={"Address"}
                name={"address"}
                placeholder="House, Apt. Suite number"
              />
              <FormInput
                name={"location"}
                placeholder={"Search or select a location"}
                isLocation={true}
                form={form}
              />
            </div>
          ),
        },
        {
          input_heading: "What type of a shared home is it?",
          name: "home_type",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("home_type")}
              name="home_type"
              initialValue={
                // getInitialValue("home_type") ??
                formValues?.home_type ?? setDefaultValue("home_type", 1)
              }
            />
          ),
        },
        {
          input_heading: "Total number of separate bedrooms?",
          name: "no_of_bedrooms",
          component: (
            <CustomSlider
              {...commonRadioProps}
              max={15}
              marks={sliderOptions?.no_of_bedrooms}
              name="no_of_bedrooms"
              initialValue={
                formValues?.no_of_bedrooms ?? sliderOptions?.no_of_bedrooms?.[7]
              }
            />
          ),
        },
        {
          input_heading: "Total number of bathrooms?",
          name: "no_of_bathrooms",
          component: (
            <CustomSlider
              {...commonRadioProps}
              max={6}
              marks={sliderOptions?.no_of_bathrooms}
              name="no_of_bathrooms"
              initialValue={
                formValues?.no_of_bathrooms ??
                sliderOptions?.no_of_bathrooms?.[3]
              }
            />
          ),
        },
        {
          input_heading: "Maximum number of housemates?",
          name: "maximum_crew",
          component: (
            <CustomSlider
              {...commonRadioProps}
              max={15}
              marks={sliderOptions?.maximum_crew}
              name="maximum_crew"
              initialValue={
                formValues?.maximum_crew ?? sliderOptions?.maximum_crew?.[7]
              }
            />
          ),
        },
      ],
    },
    {
      title: "Utilities",
      formItems: [
        {
          input_heading: "What amenities are available in the kitchen?",
          name: "available_kitchen_amenities",
          component: (
            <MultiSelectOptions
              form={form}
              onChange={getMultiInputOnchange}
              options={getOptions("available_kitchen_amenities")}
              name="available_kitchen_amenities"
              initialValue={
                // getInitialValue("available_kitchen_amenities") ??
                formValues?.available_kitchen_amenities ??
                setDefaultValue("available_kitchen_amenities", 1)
              }
            />
          ),
        },
        {
          input_heading: "Does the home have internet?",
          name: "internet",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              rentStatusButton={true}
              options={getOptions("internet")}
              name="internet"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.internet ?? setDefaultValue("internet", 0)
              }
            />
          ),
        },
        {
          input_heading: "Does the home have airconditioning?",
          name: "air_conditioning",
          component: (
            <MultiSelectOptions
              form={form}
              rentStatusButton={true}
              onChange={getMultiInputOnchange}
              options={getOptions("air_conditioning")}
              name="air_conditioning"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.air_conditioning ??
                setDefaultValue("air_conditioning", 0)
              }
            />
          ),
        },
        {
          input_heading: "What other utilities are available?",
          name: "other_utilities",
          component: (
            <MultiSelectOptions
              form={form}
              rentStatusButton={true}
              onChange={getMultiInputOnchange}
              options={getOptions("other_utilities")}
              name="other_utilities"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.other_utilities ??
                setDefaultValue("other_utilities", 0)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Services",
      formItems: [
        {
          input_heading: "Is parking available?",
          name: "parking",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              rentStatusButton={true}
              options={getOptions("parking")}
              name="parking"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.parking ?? setDefaultValue("parking", 0)
              }
            />
          ),
        },
        {
          input_heading: "Is there a storage space available?",
          name: "storage_space",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              rentStatusButton={true}
              options={getOptions("storage_space")}
              name="storage_space"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.storage_space ?? setDefaultValue("storage_space", 0)
              }
            />
          ),
        },
        {
          input_heading: "Does the home come furnished?",
          name: "furniture",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              rentStatusButton={true}
              options={getOptions("furniture")}
              name="furniture"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.furniture ?? setDefaultValue("furniture", 0)
              }
            />
          ),
        },
        {
          input_heading: "What kind of laundry appliances are available?",
          name: "laundry",
          component: (
            <MultiSelectOptions
              form={form}
              rentStatusButton={true}
              onChange={getMultiInputOnchange}
              options={getOptions("laundry")}
              name="laundry"
              initialValue={
                // getInitialValue("laundry") ??
                formValues?.laundry ?? setDefaultValue("laundry", 0)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Additional information",
      formItems: [
        {
          input_heading: "Are pets allowed in the living spaces?",
          name: "pets",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("pets")}
              name="pets"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.pets ?? setDefaultValue("pets", 1)
              }
            />
          ),
        },
        {
          input_heading: "Are you allowed to smoke in the living areas?",
          name: "smoking",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("smoking")}
              name="smoking"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.smoking ?? setDefaultValue("smoking", 1)
              }
            />
          ),
        },
        {
          input_heading: "Additional policies",
          name: "additional_policies",
          component: (
            <AddInputs
              form={form}
              title="Enter any additional policies for your shared space"
              name="additional_policies"
              placeHolder="Enter any additional policy"
              placeHolder1="No guests"
              placeHolder2="No noise after 12 a.m"
              placeHolder3="No alcohol"
            />
          ),
        },
        {
          input_heading: "How noisy would you say that your shared home is?",
          name: "noise",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("noise")}
              name="noise"
              initialValue={
                // getInitialValue("laundry_appliances") ??
                formValues?.noise ?? setDefaultValue("noise", 1)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Finalize",
      formItems: [
        {
          input_heading: "Places of interest",
          name: "place_of_interest",
          component: (
            <AddInputs
              form={form}
              title="Enter places of interest within a 5km radius:"
              name="place_of_interest"
              placeHolder="Enter a place of interest"
              placeHolder1="Aldi’s Supermarket"
              placeHolder2="Brunsen Park"
              placeHolder3="WTC Metro station"
            />
          ),
        },
        {
          input_heading: "Hub Description",
          name: "hub_description",
          component: (
            <FormInput
              label="Describe your shared home in a few words:"
              name="hub_description"
              placeholder="Our house is ..."
              isTextArea={true}
            />
          ),
        },
      ],
    },
  ];
}
