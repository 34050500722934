import coverImg from "../../assets/images/cover.png";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import { Divider } from "antd";
import "./Login.styles.scss";
import LoginForm from "../Forms/LoginForm/LoginForm";

const Login = () => {
  return (
    <div className="container">
      <div className="image-container">
        <img className="cover-image" src={coverImg} alt="cover" />
      </div>
      <Divider
        className="vd"
        type="vertical"
        style={{ height: "80%", backgroundColor: "#4E4E61", width: "2.5px" }}
      />
      <div className="form-container">
        <img
          src={logo}
          className="logo"
          alt="logo"
          width="200px"
          height="40px"
        />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
