import "./InviteRequestCard.styles.scss";
import { Avatar, Button, Card, Modal } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import api from "../../api/api";
import hubIcon from "../../assets/images/SVG/hub_icon.svg";
import { useQueryClient } from "react-query";

const SendInviteRequestCard = ({ inviteRequest, loading }) => {
  const { confirm } = Modal;

  const queryClient = useQueryClient();
  console.log(inviteRequest);
  const toCamelCase = (str) => {
    return str?.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const senderFullNameCamelCase = toCamelCase(
    inviteRequest?.no_hub_crew_info?.full_name
  );

  const handleDeleteInviteRequest = async () => {
    const id = inviteRequest?.invitationrequest_id;
    try {
      const apiResponse = await api.cancelInvitationRequest(id);
      if (apiResponse?.status === 204) {
        queryClient.invalidateQueries("invitationRequestList");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Confirmation",
      content: `Are you sure that you want to cancel ${
        inviteRequest?.type === "invitation" ? "invite" : "request"
      } ?`,
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },

      onOk() {
        handleDeleteInviteRequest();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Card className="inviteRequest-card" loading={loading}>
      {inviteRequest?.type === "invitation" ? (
        <Avatar size={56} className="marginSide" src={profileAvatar} />
      ) : (
        <img
          className="marginSide"
          height="70px"
          width="70px"
          src={hubIcon}
          alt="hub-icon"
        />
      )}
      <div className="content-container">
        <p className="content">
          {inviteRequest?.type === "invitation"
            ? `You have invited  ${senderFullNameCamelCase} to join your hub`
            : `You have requested to join ${inviteRequest?.hub_info?.hub_name}`}
        </p>
        <div className="confirm-buttons">
          <Button
            shape="round"
            className="cancel-button btn-requested"
            onClick={showDeleteConfirm}
          >
            {inviteRequest?.type === "invitation"
              ? "Cancel invite"
              : "Cancel request"}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SendInviteRequestCard;
