import { Button, Form, Avatar, Input, Upload, message, Spin } from "antd";
import { useState } from "react";
import { PasswordInput } from "antd-password-input-strength";
import profileAvatar from "../../../assets/images/SVG/avatar.svg";
import api from "../../../api/api.js";
import editIcon from "../../../assets/images/SVG/edit.svg";
import { useNavigate } from "react-router";
import { auth } from "../../../api/firebase.js";
import "./RegisterForm.styles.scss";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { handleLogin } from "../LoginForm/LoginForm.jsx";
import { useMutation, useQueryClient } from "react-query";

export const handleRegistration = async (values, type) => {
  if (type === "email") {
    values.login_type = "Email";
  } else if (type === "apple") {
    values.login_type = "Apple";
  } else if (type === "google") {
    values.login_type = "Google";
  }
  try {
    const response = await api.registerUser(values, type);
    console.log(response);

    return { success: true };
  } catch (error) {
    // Handle any other errors that might occur during registration
    console.error("Error in handleRegistration:", error);
    // Display an error message to the user
    return { success: false, error: error?.message };
  }
};

const RegisterForm = ({ verificationPage, setVerificationPage }) => {
  const [form] = Form.useForm();
  const [passwordValid, setPasswordValid] = useState(false);
  const queryClient = useQueryClient();
  const [activePage, setActivePage] = useState("mail-section");
  const [avatarImage, setAvatarImage] = useState(null);
  const [userCredential, setUserCredential] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignInButtonClick = () => {
    navigate("/login");
  };

  const validatePassword = (value) => {
    const isValid =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/.test(
        value
      );
    setPasswordValid(isValid);
  };

  const handleNextPage = () => {
    setActivePage("name-section");
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    // return true;
    return isJpgOrPng && isLt2M;
  };

  const handleUploadChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setAvatarImage(url);
    });
  };

  const handleFirebaseRegistration = async (values) => {
    validatePassword(values.password);
    if (!passwordValid) {
      message.error(
        "Password must contain at least one letter, one number, one symbol, and be at least 8 characters long"
      );
      return;
    }
    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;
      console.log("User registered successfully:", user);
      setUserCredential(user);
      handleNextPage();
    } catch (error) {
      console.error("Registration error:", error.code, error.message);
      message.error("Email already in use");
    }
    setLoading(false);
  };

  const handleVerification = async () => {
    try {
      setLoading(true);
      await sendEmailVerification(auth.currentUser);
      console.log("verficiation send");
      setVerificationPage(true);
    } catch (error) {
      console.error("Verification error:", error.code, error.message);
    }
    setLoading(false);
  };

  const loginMutation = useMutation((values) => handleLogin(values, "email"), {
    onSuccess: async () => {
      console.log("Login successful!");
      // Fetch current user details
      const currentUserResponse = await api.getCurrentUser();
      console.log(currentUserResponse);

      if (currentUserResponse) {
        // Redirect to UserHome
        queryClient.setQueryData("currentUser", currentUserResponse);
        if (currentUserResponse?.hub) {
          navigate(`/user/${currentUserResponse.crew_id}`);
        } else {
          navigate(`/user/${currentUserResponse.crew_id}/hub`);
        }
      }
    },
    onError: (error) => {
      console.error("Login error:", error);
      const errorMessage = error.message;
      console.log(errorMessage);
      message.error(errorMessage || "An error occurred during login");
    },
  });

  const handleEmailRegistration = async () => {
    try {
      const formData = new FormData();
      if (avatarImage) {
        formData.append(
          "profile_picture",
          form.getFieldValue("profile_picture")[0]?.originFileObj
        );
      }
      formData.append("first_name", form.getFieldValue("first_name"));
      formData.append("last_name", form.getFieldValue("last_name"));
      formData.append("uid", userCredential?.uid);
      formData.append("email", userCredential?.email);
      formData.append("login_type", "Email");

      const result = await handleRegistration(formData, "email");
      // Check if registration was successful
      if (result.success) {
        await loginMutation.mutateAsync({ uid: userCredential?.uid });
      } else {
        // Handle registration failure and display error message
        message.error(result.error || "Registration failed");
        setActivePage("mail-section");
        navigate("/register");
      }
    } catch (error) {
      // Handle any unexpected errors during registration
      console.error("Unexpected error during registration:", error);
      message.error("Registration failed. Please try again.");
    }
    form.resetFields();
    setVerificationPage(false);
  };

  const upload = (
    <div className="avatar-container">
      <Avatar size={64} src={profileAvatar} />
      <div className="profile-edit-btn">
        <img width="10px" height="10px" src={editIcon} alt="edit-btn" />
      </div>
    </div>
  );

  return (
    <div className="register-form">
      {loginMutation.isLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {activePage === "mail-section" && (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFirebaseRegistration}
              requiredMark={false}
            >
              <>
                <Form.Item
                  label="E-mail address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    { required: true, message: "Please enter email id" },
                  ]}
                >
                  <Input placeholder="Enter your email ID here" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      validator: async (_, value) => {
                        validatePassword(value);
                        return Promise.resolve();
                      },
                      message:
                        "Password must contain at least one letter, one number, one symbol, and be at least 8 characters long",
                    },
                  ]}
                  validateStatus={!passwordValid ? "error" : ""}
                >
                  <PasswordInput
                    style={{ marginBottom: "15px" }}
                    placeholder="Enter a strong password here"
                    onLevelChange={(isValid) => setPasswordValid(isValid)}
                  />
                </Form.Item>
                <p className="password-content">
                  Use 8 or more characters with a mix of letters, numbers &
                  symbols.
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="marginAuto"
                  shape="round"
                  loading={loading}
                >
                  Get started - It's free
                </Button>

                <p style={{ color: "white", textAlign: "center" }}>
                  Already have an account?
                </p>
                <Button
                  type="default"
                  className="marginAuto"
                  shape="round"
                  onClick={handleSignInButtonClick}
                >
                  Sign in
                </Button>
              </>
            </Form>
          )}
          {activePage === "name-section" && (
            <Form form={form} layout="vertical" requiredMark={false}>
              {!verificationPage ? (
                <>
                  <Form.Item
                    getValueFromEvent={(event) => {
                      console.log({ event });
                      return event?.fileList;
                    }}
                    className="profile-section"
                    name="profile_picture"
                  >
                    <Upload
                      showUploadList={false}
                      accept=".jpeg, .jpg, .png"
                      beforeUpload={beforeUpload}
                      onChange={handleUploadChange}
                      maxCount={1}
                      fileList={avatarImage ? [avatarImage] : []}
                      customRequest={(info) => {
                        // setAvatarImage(info.file);
                      }}
                    >
                      {avatarImage ? (
                        <div className="avatar-container">
                          <Avatar size={64} src={avatarImage} />
                        </div>
                      ) : (
                        upload
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="What shall we call you?"
                    name="first_name"
                    rules={[
                      { required: true, message: "Please enter first name " },
                    ]}
                  >
                    <Input placeholder="Enter your first name here" />
                  </Form.Item>
                  <Form.Item
                    name="last_name"
                    rules={[
                      { required: true, message: "Please enter last name " },
                    ]}
                  >
                    <Input placeholder="Enter your last name here" />
                  </Form.Item>
                  <Button
                    type="primary"
                    className="marginAuto"
                    shape="round"
                    onClick={handleVerification}
                    loading={loading}
                  >
                    Send Verifiation Link
                  </Button>
                </>
              ) : (
                <div className="verification">
                  <p className="verification-email">{`An email verification link has been sent to: ${userCredential?.email}`}</p>
                  <Button
                    type="primary"
                    className="marginAuto"
                    shape="round"
                    onClick={handleEmailRegistration}
                    style={{ marginBottom: "10px" }}
                  >
                    Skip verification
                  </Button>
                  <Button
                    className="marginAuto"
                    onClick={handleVerification}
                    shape="round"
                  >
                    Resend verification link
                  </Button>
                  <p className="content">
                    Please verify your email within 30 days to continue using
                    your account
                  </p>
                </div>
              )}
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default RegisterForm;
