import { useState } from "react";
import { Avatar, Drawer, Form, Modal, message } from "antd";
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm.component";
import AssignmentDetailsCard from "../AssignmentDetailsCard/AssignmentDetailsCard.component.jsx";
import AssignmentDetailsPage from "../AssignmentDetailsPage/AssignmentDetailsPage.component.jsx";
import dayjs from "dayjs";
import profileAvatarBlue from "../../assets/images/SVG/avatar-blue.svg";
import { dayNamesMap } from "../../variables";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import api from "../../api/api.js";

const AssignmentContainer = ({ assignment, toDoPage }) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;

  const queryClient = useQueryClient();
  const [compressed, setCompressed] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [editTaskData, setEditTaskData] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [showWeekdaySelector, setShowWeekdaySelector] = useState(false);
  const [isEditingSeries, setIsEditingSeries] = useState(false);

  const formattedDate = new Date(assignment.to_do_on).toLocaleDateString(
    "en-US",
    {
      weekday: "short",
      month: "short",
      day: "numeric",
    }
  );

  const handleEditTask = (task, editType) => {
    if (editType === "editSeries") {
      setIsEditingSeries(true);
    } else {
      setIsEditingSeries(false);
    }
    const formattedToDoOn = task?.to_do_on
      ? dayjs(task.to_do_on).format("YYYY-MM-DD")
      : null;
    const formattedrepeatTill = task?.repeat_till
      ? dayjs(task.repeat_till).format("YYYY-MM-DD")
      : null;
    const assignedCrews = task?.assigned_to?.map(
      (assignedTo) => assignedTo.full_name
    );
    const selectedRepeatId = task?.repeat_id || null;
    const updatedTaskData = {
      ...task,
      repeat_id: selectedRepeatId,
      to_do_on: formattedToDoOn,
      repeat_till: formattedrepeatTill,
      assigned_to: assignedCrews,
    };
    setEditTaskData(updatedTaskData);
    setEditDrawerVisible(true);
  };

  const toggleEditDrawer = (visible) => {
    setEditDrawerVisible(visible);
  };

  const deleteAssignment = useMutation(
    async ({ assignmentId, repeatId, deleteType }) => {
      let response;
      if (deleteType === "deleteSeries") {
        response = await api.deleteAssignment(assignmentId, {
          repeat_id: repeatId,
        });
      } else {
        response = await api.deleteAssignment(assignmentId);
      }
      console.log({ response });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["hubAssignments", toDoPage, 1]);
        queryClient.invalidateQueries("currentUser");
        message.success("Task deleted successfully");
      },
      onError: (error) => {
        console.log("Error deleting task:", error);
        message.error("Error deleting task");
      },
    }
  );

  const handleDeleteAssignment = async (taskToDelete, deleteType) => {
    const { assignment_id: assignmentId, repeat_id: repeatId } = taskToDelete;
    deleteAssignment.mutateAsync({ assignmentId, repeatId, deleteType });
    setCompressed(false);
  };

  const assignmentStatusEditMutation = useMutation(
    async ({ assignmentId, values }) => {
      const response = await api.editAssignment(assignmentId, values);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["hubAssignments", toDoPage, 1]);
        queryClient.invalidateQueries("currentUser");
        message.success("Assignment status updated successfully");
      },
      onError: (error) => {
        console.error(error);
        message.error("Error updating assignment status");
      },
    }
  );

  const handleDoneButtonClick = async (assignment) => {
    try {
      const updatedAssignment = {
        ...assignment,
        status: "Completed",
        assigned_to: assignment.assigned_to.map((crew) => crew.crew_id),
      };
      await assignmentStatusEditMutation.mutateAsync({
        assignmentId: assignment.assignment_id,
        values: updatedAssignment,
      });
    } catch (error) {
      console.error("Error updating assignment status:", error);
    }
    setCompressed(false);
  };

  const showConfirm = ({ title, content, okText, onOk }) => {
    confirm({
      title: title,
      content,
      icon: null,
      okText: okText,
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk,
      onCancel() {
        console.log("Cancel");
        setCompressed(false);
      },
    });
  };

  const showDeleteConfirm = (assignment, key) => {
    const title = "Confirmation";
    const content = `Are you sure you want to delete the assignment${
      key === "deleteSeries" ? " series" : ""
    }?`;
    const okText = "Confirm";
    showConfirm({
      title,
      content,
      okText,
      onOk: () => handleDeleteAssignment(assignment, key),
    });
  };

  const showDoneConfirm = (assignment) => {
    const title = "Mark as complete?";
    const content = (
      <div className="complete-confirmation-content">
        <span style={{ fontSize: "4rem", marginRight: "10px" }}>
          {assignment?.assignment_emoji_grouping}
        </span>
        <div className="assignment-detail">
          <span className="name">{assignment?.assignment_name}</span>
          <span className="date">{`Deadline: ${formattedDate}`}</span>
          <div className="assigned_to">
            <Avatar.Group
              max={{
                count: 2,
                popover: {
                  trigger: "click",
                },
                style: {
                  cursor: "pointer",
                },
              }}
              size={24}
            >
              {assignment?.assigned_to?.map((crew) => (
                <Avatar
                  key={crew?.crew_id}
                  className={
                    assignment?.assigned_to?.length > 1 ? "marginSide" : ""
                  }
                  src={
                    crew?.profile_picture_url === null ||
                    crew?.profile_picture_url.endsWith("default.jpg")
                      ? profileAvatarBlue
                      : crew?.profile_picture_url
                  }
                />
              ))}
            </Avatar.Group>
          </div>
        </div>
      </div>
    );
    const okText = (
      <div className="confirm-btn">
        Complete
        <span>{`(${assignment?.assignment_points} points)`}</span>
      </div>
    );
    showConfirm({
      title,
      content,
      okText,
      onOk: () => handleDoneButtonClick(assignment),
    });
  };

  return (
    <>
      <AssignmentDetailsCard
        assignment={assignment}
        onEditTask={handleEditTask}
        formattedDate={formattedDate}
        showDeleteConfirm={showDeleteConfirm}
        showDoneConfirm={showDoneConfirm}
        compressed={compressed}
        setCompressed={setCompressed}
      />
      <Drawer
        title="Edit Assignment"
        className="drawer-right edit-assignment-drawer"
        placement="right"
        onClose={() => toggleEditDrawer(false)}
        open={editDrawerVisible}
        closeIcon={<ArrowRightOutlined />}
      >
        <AssignmentForm
          form={form}
          isEdit={true}
          initialValues={editTaskData}
          setVisible={setEditDrawerVisible}
          isEditingSeries={isEditingSeries}
          setSelectedDays={setSelectedDays}
          selectedDays={selectedDays}
          dayNamesMap={dayNamesMap}
          showWeekdaySelector={showWeekdaySelector}
          setShowWeekdaySelector={setShowWeekdaySelector}
          setCompressed={setCompressed}
          toDoPage={toDoPage}
        />
      </Drawer>
      <Drawer
        className="drawer-right"
        placement="right"
        onClose={() => setDetailsDrawerVisible(false)}
        open={detailsDrawerVisible}
        closeIcon={false}
      >
        <AssignmentDetailsPage
          assignment={assignment}
          onEditTask={handleEditTask}
        />
      </Drawer>
    </>
  );
};

export default AssignmentContainer;
