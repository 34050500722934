import { Avatar, Button, Card } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import "./CaptainUserCard.styles.scss";
import UserProfileDetailsComponent from "../UserProfileDetails/UserProfileDetails.component";

const CaptainUserCard = ({ crew, details, currentUser }) => {
  return (
    <Card className="user-card">
      <Avatar
        size={80}
        src={
          //   crew?.profile_picture_url === null ||
          //   crew?.profile_picture_url.endsWith("default.jpg")
          //     ? profileAvatar
          //     : crew?.profile_picture_url
          profileAvatar
        }
      />
      <h1>{crew?.full_name}</h1>
      <p>Captain</p>
      <UserProfileDetailsComponent details={details} />
      {crew?.admin_id !== currentUser?.crew_id && (
        <Button className="userCard-btn" type="primary" shape="round">
          Message
        </Button>
      )}
      {!currentUser?.hub && (
        <Button className="userCard-btn" shape="round">
          Request to join hub
        </Button>
      )}
    </Card>
  );
};

export default CaptainUserCard;
