import { Button, Divider, Spin, message } from "antd";
import coverImg from "../../assets/images/cover.png";
import googleImg from "../../assets/images/SVG/google-logo.svg";
import appleImg from "../../assets/images/SVG/apple-black.svg";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import "./Home.styles.scss";
import { useNavigate } from "react-router";
import { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
import { handleRegistration } from "../Forms/RegisterForm/RegisterForm";
import { handleLogin } from "../Forms/LoginForm/LoginForm";
import api from "../../api/api";
import { useMutation, useQueryClient } from "react-query";

const Home = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { appleSignIn, googleSignIn } = UserAuth();
  const [showButtons, setShowButtons] = useState(false);
  const [loadingGoogleSignIn, setLoadingGoogleSignIn] = useState(false);

  const handleGetStartedClick = () => {
    setShowButtons(true);
  };

  const handleHaveAccountClick = () => {
    navigate("/login");
  };

  const socialLoginMutation = useMutation(
    (values) => handleLogin(values, values.type),
    {
      onSuccess: async () => {
        console.log("Google Login successful!");
        // Fetch current user details
        const currentUserResponse = await api.getCurrentUser();
        console.log(currentUserResponse);

        if (currentUserResponse) {
          // Redirect to UserHome
          queryClient.setQueryData("currentUser", currentUserResponse);
          if (currentUserResponse?.hub) {
            navigate(`/user/${currentUserResponse.crew_id}`);
          } else {
            navigate(`/user/${currentUserResponse.crew_id}/hub`);
          }
        }
      },
      onError: (error) => {
        console.error("Google Login error:", error);
        const errorMessage = error.message;
        console.log(errorMessage);
        message.error(errorMessage || "An error occurred during Google login");
      },
    }
  );

  const handleAppleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await appleSignIn();
      console.log({ userData });
      const email = userData?._tokenResponse?.email;
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;

      let firstName = "";
      let lastName = "";

      if (userData?.user?.displayName) {
        const splitName =
          userData?.user?.displayName?.trim().split(/\s+/) || [];
        firstName = splitName[0] || "";
        lastName = splitName?.slice(1).join(" ") || "";
      } else {
        const emailNamePart = email.split("@")[0];
        const nameLength = emailNamePart?.length;
        const halfLength = Math.ceil(nameLength / 2);
        firstName = emailNamePart?.substring(0, halfLength);
        lastName = emailNamePart?.substring(halfLength);
      }

      const values = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
      };

      const loginValues = {
        uid: uid,
        login_type: "Apple",
      };
      const registrationResult = await handleRegistration(values, "apple");
      console.log(registrationResult);
      if (registrationResult.success === false) {
        await socialLoginMutation.mutateAsync(loginValues);
      } else {
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  const handleGoogleButtonClick = async () => {
    setLoadingGoogleSignIn(true);
    try {
      const userData = await googleSignIn();
      console.log({ userData });
      const email = userData?.user?.email;
      const profilePicture = userData?._tokenResponse?.photoUrl;
      const splitName = userData?._tokenResponse?.displayName?.split(" ");
      const firstName = splitName[0];
      const lastName = splitName.slice(1).join(" ");
      const userId = userData?.user?.uid;
      const secretKey = "yourSecretKey";
      const uid = userId + secretKey;

      const values = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        uid: uid,
        password: uid,
        profile_picture_url: profilePicture,
      };

      const loginValues = {
        uid: uid,
        login_type: "Google",
      };
      const registrationResult = await handleRegistration(values, "google");
      console.log(registrationResult);
      if (registrationResult.success === false) {
        await socialLoginMutation.mutateAsync(loginValues);
      } else {
        await socialLoginMutation.mutateAsync(loginValues);
      }
    } catch (err) {
      console.error(err);
      setLoadingGoogleSignIn(false);
    } finally {
      setLoadingGoogleSignIn(false);
    }
  };

  const handleSignUpButtonClick = () => {
    navigate("/register");
  };

  return (
    <div className="container">
      <div className="image-container">
        <img className="cover-image" src={coverImg} alt="cover" />
      </div>
      <Divider
        type="vertical"
        className="vd"
        style={{
          height: "80%",
          backgroundColor: "#4E4E61",
          width: "2.5px",
        }}
      />
      <div className="content-container">
        <img
          src={logo}
          className="logo"
          alt="logo"
          width="200px"
          height="40px"
        />

        {!showButtons ? (
          <div className="button-container ">
            <Button
              type="primary"
              shape="round"
              onClick={handleGetStartedClick}
            >
              Get Started
            </Button>
            <Button
              type="default"
              shape="round"
              onClick={handleHaveAccountClick}
            >
              I have an account
            </Button>
          </div>
        ) : loadingGoogleSignIn ? (
          <div className="loading-spinner" style={{ marginBottom: "170px" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="button-container ">
            <Button
              className="btn-white"
              shape="round"
              onClick={handleAppleButtonClick}
            >
              <img
                src={appleImg}
                alt="apple"
                width="18px"
                height="18px"
                style={{
                  marginRight: "10px",
                  marginBottom: "1px",
                }}
              />
              Sign up with Apple
            </Button>
            <Button
              className="btn-white"
              shape="round"
              onClick={handleGoogleButtonClick}
            >
              <img
                src={googleImg}
                alt="google"
                width="20px"
                height="20px"
                style={{ marginRight: "10px" }}
              />
              Sign up with Google
            </Button>
            <Button shape="round" onClick={handleSignUpButtonClick}>
              Sign up with E-mail
            </Button>
            <p
              style={{ color: "white", textAlign: "center", fontSize: "14px" }}
            >
              Already have an account?
            </p>
            <Button
              className="marginAuto"
              shape="round"
              onClick={handleHaveAccountClick}
            >
              Sign in
            </Button>
            <p style={{ textAlign: "center" }}>
              By registering, you agree to our Terms of Use. Learn how we
              collect, use and share your data.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
