import React, { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Spin } from "antd";

const MapContext = createContext();
const libraries = ["maps", "marker"];

export const MapLoaderProvider = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCkd-gjKKiY98fNXIaWaO5sjSeUPZpG6n4", // Your API Key
    libraries,
    mapIds: ["77e046c87bb0356f"],
  });

  if (!isLoaded) {
    return <Spin />;
  }

  return (
    <MapContext.Provider value={{ isLoaded }}>{children}</MapContext.Provider>
  );
};

export const useMapLoader = () => useContext(MapContext);
