import { useState } from "react";
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails.component.jsx";
import { Button, Card, Drawer, Form, Spin, Tabs } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm.component.jsx";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { dayNamesMap } from "../../variables";
import "./AssignmentListMenu.styles.scss";
import api from "../../api/api.js";

const AssignmentListMenu = () => {
  const [form] = Form.useForm();
  const { hubId } = useParams();
  const [toDoPage, setToDoPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const { data: hubAssignments, isLoading } = useQuery(
    ["hubAssignments", toDoPage, completedPage],
    async () => {
      const assignmentData = await api.getHubAssignments(
        hubId,
        toDoPage,
        completedPage
      );
      return assignmentData;
    }
  );

  const [showWeekdaySelector, setShowWeekdaySelector] = useState(false);
  const [selectedMenuKey, setSelectedMenuKey] = useState("todo");
  const [selectedDays, setSelectedDays] = useState([]);
  const [visible, setVisible] = useState(false);
  const [className, setClassName] = useState(false);

  const items = [
    {
      label: "To do",
      key: "todo",
    },
    {
      label: "Completed",
      key: "completed",
    },
  ];

  const handleTabChange = (key) => {
    setSelectedMenuKey(key);
  };

  const toDoNext = () => {
    setToDoPage(toDoPage + 1);
  };

  const toDoPrev = () => {
    setToDoPage(toDoPage - 1);
  };

  const completedNext = () => {
    setCompletedPage(completedPage + 1);
  };

  const completedPrev = () => {
    setCompletedPage(completedPage - 1);
  };

  return (
    <div className="assignmentListMenu-container">
      <Tabs
        className="assignmentList-menu"
        activeKey={selectedMenuKey}
        onChange={handleTabChange}
        items={items}
        tabBarStyle={{
          display: "flex",
          justifyContent: "space-between",
        }}
      />
      <div className="assignment-list">
        {selectedMenuKey === "todo" ? (
          <>
            <Card className="add-assignment" onClick={() => setVisible(true)}>
              <p>Add new assignment</p>
              <PlusCircleOutlined />
            </Card>
            {isLoading ? (
              <Spin className="spin" size="large" />
            ) : (
              <>
                <AssignmentDetails
                  assignments={hubAssignments?.to_do_assignments}
                  toDoPage={toDoPage}
                />
                <div className="buttons-conatiner">
                  {toDoPage > 1 && (
                    <Button
                      type="text"
                      className="load-btn prev-btn"
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => toDoPrev()}
                    >
                      Previous
                    </Button>
                  )}
                  {hubAssignments?.to_do_has_next && (
                    <Button
                      type="text"
                      className="load-btn next-btn"
                      onClick={() => toDoNext()}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <AssignmentDetails
              assignments={hubAssignments?.completed_assignments}
            />
            <div className="buttons-conatiner">
              {completedPage > 1 && (
                <Button
                  type="text"
                  className="load-btn prev-btn"
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => completedPrev()}
                >
                  Previous
                </Button>
              )}
              {hubAssignments?.completed_has_next && (
                <Button
                  type="text"
                  className="load-btn next-btn"
                  onClick={() => completedNext()}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      <Drawer
        title="New Assignment"
        className="drawer-right assignment-drawer"
        placement="right"
        onClose={() => {
          setVisible(false);
          form.resetFields();
          setSelectedDays([]);
          // Uncheck the switch
          setShowWeekdaySelector(false);
        }}
        rootClassName={className ? "root-assignment-drawer" : ""}
        open={visible}
        closeIcon={
          <Button shape="round" className="cancel-btn btn-blue">
            Cancel
          </Button>
        }
        extra={
          className ? (
            <ArrowLeftOutlined
              onClick={() => {
                setClassName(false);
              }}
            />
          ) : (
            <ArrowRightOutlined
              onClick={() => {
                setClassName(true);
              }}
            />
          )
        }
        maskClosable={className ? false : true}
        mask={className ? false : true}
      >
        <AssignmentForm
          form={form}
          setVisible={setVisible}
          setSelectedDays={setSelectedDays}
          selectedDays={selectedDays}
          dayNamesMap={dayNamesMap}
          showWeekdaySelector={showWeekdaySelector}
          setShowWeekdaySelector={setShowWeekdaySelector}
          toDoPage={toDoPage}
        />
      </Drawer>
    </div>
  );
};

export default AssignmentListMenu;
