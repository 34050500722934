import { Button, Form, Input, Select } from "antd";
import "./HubForm.styles.scss";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../api/api";
import { useNavigate } from "react-router";

const HubForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData("currentUser");
  const { Option } = Select;
  const options = [];
  for (let i = 2; i <= 10; i++) {
    options.push(i);
  }

  const createHubMutation = useMutation((values) => api.createHub(values), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("currentUser");
      form.resetFields();
      navigate(`/user/${currentUser?.crew_id}/hub/${data?.hub_id}`);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const submitHub = (values) => {
    createHubMutation.mutate(values);
  };

  return (
    <div className="hub-form">
      <Form form={form} layout="vertical" onFinish={submitHub}>
        <Form.Item
          name="hub_name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input placeholder="Enter a name for your hub" />
        </Form.Item>

        <Form.Item
          name="maximum_crew"
          type="number"
          rules={[
            {
              required: true,
              message: "Please select number of cohubitants you want add",
            },
          ]}
        >
          <Select
            placeholder="Select a maximum hub size"
            allowClear
            dropdownStyle={{
              backgroundColor: "#292932",
              fontFamily: "inherit",
              height: "fit-content",
            }}
          >
            {options.map((value, index) => (
              <Option key={index} value={value} label={`${value}`}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          type="primary"
          className="hub-btn"
          htmlType="submit"
          shape="round"
          loading={createHubMutation.isLoading}
        >
          Create Hub
        </Button>
      </Form>
    </div>
  );
};

export default HubForm;
