export const emojiList = [
  { value: "cooking", emoji: "👩‍🍳" },
  { value: "cleaning", emoji: "🧹" },
  { value: "laundry", emoji: "🧺" },
  { value: "shopping", emoji: "🛒" },
  { value: "washing", emoji: "🧼" },
];

export const dayNamesMap = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};

// Abbreviations for weekdays
export const dayAbbreviations = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
};

export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
