import { useContext, createContext, useState } from "react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  // onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../api/firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user] = useState({});
  // const [loginState, setLoginState] = useState();

  const provider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  const signIn = async (authProvider) => {
    const res = await signInWithPopup(auth, authProvider);
    console.log({ res });
    return res;
  };

  const googleSignIn = () => signIn(provider);
  const appleSignIn = () => signIn(appleProvider);

  const logOut = () => {
    signOut(auth);
  };

  // const currentUser = useQuery("currentUser", api.getCurrentUser, {
  //   enabled: false,
  // });
  // console.log(currentUser);

  // const checkLogin = async () => {
  //   // const data = await axios.get(api)
  //   // setLoginState(data)

  // }

  // useEffect(() => {

  //   // const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //   //   setUser(currentUser);
  //   // });
  //   // return () => {
  //   //   unsubscribe();
  //   // };
  //   checkLogin()
  // }, []);

  // useEffect(() => {
  //   if(!loginState) navigate('/login')
  // else {
  // refect currentuser
  // }
  // }, [loginState])

  return (
    <AuthContext.Provider value={{ googleSignIn, appleSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
