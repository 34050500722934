import useToggle from "./hooks/useToggleProfileIcons";
import { Button, Collapse } from "antd";
import "./UserProfileDetails.styles.scss";
import { useLocation } from "react-router";
import { MinusOutlined } from "@ant-design/icons";

const UserProfileDetailsComponent = ({
  details,
  isLastStageAndItem,
  onIconClick,
  isInCrewList,
}) => {
  const location = useLocation();
  const { showText, toggleShowText } = useToggle(false);
  const isUserInFormPath =
    location.pathname.includes("edit-profile") ||
    location.pathname.includes("settings") ||
    location.pathname.includes("hub-view");

  let className = "";

  if (isUserInFormPath && isInCrewList) {
    className = "profile-details-small";
  } else if (isUserInFormPath) {
    className = "profile-details-container";
  } else {
    className = "profile-details-small";
  }

  const items = [
    {
      key: "1",
      label: !showText && (
        <div className="icon-section">
          {details?.map((item, index) => {
            const key = Object.keys(item)[0];
            const detail = item[key];

            return (
              detail.icon && (
                <img
                  className="icon"
                  key={index}
                  src={detail.icon}
                  alt="icon"
                />
              )
            );
          })}
        </div>
      ),
      children: (
        <div className="section">
          {details?.map((item, index) => {
            const key = Object.keys(item)[0];
            const detail = item[key];
            return (
              detail.icon && (
                <div
                  className={`item ${isLastStageAndItem && "pointer"}`}
                  key={index}
                  onClick={
                    isLastStageAndItem ? () => onIconClick(key) : undefined
                  }
                >
                  <img className="icon" src={detail.icon} alt="icon" />
                  {showText && <p className="icon-text">{detail.text}</p>}
                </div>
              )
            );
          })}
          {isLastStageAndItem && (
            <Button
              shape="circle"
              className="minus-button"
              icon={<MinusOutlined />}
              onClick={toggleShowText}
            />
          )}
        </div>
      ),
      showArrow: false,
    },
  ];

  const handleClick = () => {
    if (isLastStageAndItem) {
      !showText && toggleShowText();
    } else {
      toggleShowText();
    }
  };

  return (
    <div className={className} onClick={handleClick}>
      <Collapse activeKey={showText ? "1" : null} ghost items={items} />
    </div>
  );
};

export default UserProfileDetailsComponent;
