import { Input } from "antd";
import { debounce } from "lodash";
import api from "../../api/api";
import CrewSearchResult from "../CrewSearchResult/CrewSearchResult.component";
import "./AddCrew.styles.scss";
import { useEffect, useState } from "react";

const AddCrew = () => {
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearch = debounce(async (value) => {
    setSearchQuery(value);

    try {
      const response = await api.searchCrew(value);

      // Extract relevant data from the API response
      const results = response?.results;
      console.log(results);

      setSearchResults(results);
    } catch (error) {
      console.error("Error searching:", error);
    }
  }, 500);

  const handleInputChange = (e) => {
    // Trigger search on input change
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const initialSearch = async (value) => {
      try {
        const response = await api.searchCrew(value);
        // Extract relevant data from the API response
        const results = response?.results;
        setSearchResults(results);
      } catch (error) {
        console.error("Error searching:", error);
      }
    };
    initialSearch("a");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="add-crew">
      <div className="searchbar-container">
        <Search
          className="search-bar"
          placeholder="Find crewmates"
          onSearch={debouncedSearch}
          onChange={handleInputChange}
        />
      </div>
      {searchResults?.length > 0 ? (
        <div className="search-result">
          <CrewSearchResult searchResult={searchResults} />
        </div>
      ) : (
        searchQuery &&
        searchResults?.length === 0 && (
          <div className="search-result">
            <p style={{ color: "white" }}>
              No results found for "{searchQuery}"
            </p>
          </div>
        )
      )}
    </div>
  );
};

export default AddCrew;
