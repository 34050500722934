import { Card } from "antd";
import "./AssignmentDetails.styles.scss";
import AssignmentContainer from "../AssignmentContainer/AssignmentContainer";

const AssignmentDetails = ({ assignments, toDoPage }) => {
  return assignments?.length === 0 ? (
    <div className="assignmenDetails-container">
      <Card className="assignment-emptyCard">
        <p>No assignments</p>
      </Card>
    </div>
  ) : (
    <div className="assignmenDetails-container">
      {assignments?.map((assignment) => (
        <AssignmentContainer
          key={assignment.assignment_id}
          assignment={assignment}
          toDoPage={toDoPage}
        />
      ))}
    </div>
  );
};

export default AssignmentDetails;
