import { Button, Divider, Spin } from "antd";
import CrewList from "../CrewList/CrewList.component";
import AssignmentListMenu from "../AssignmentListMenu/AssignmentListMenu.component";
import "./HubManagement.styles.scss";
import { useQuery } from "react-query";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router";
import { useUserContext } from "../../context/UserContext";

const HubManagement = () => {
  const { hubId } = useParams();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { currentUser } = useUserContext();
  console.log({ currentUser });

  const {
    data: hubDetails,
    isLoading,
    isError,
  } = useQuery(["hubDetails", hubId], async () => {
    const hubData = await api.getHubDetails(hubId);
    return hubData;
  });

  return (
    <>
      {isError ? (
        <>
          <h1 style={{ textAlign: "center", color: "white" }}>
            Error fetching hub details message.success
          </h1>
        </>
      ) : isLoading ? (
        <Spin className="spin" size="large" />
      ) : (
        <div className="hubManagement-container">
          <div className="crew-assignment">
            <div className="crew-side">
              <h1 className="hub-name">{hubDetails?.hub_name}</h1>
              <p
                className="hub-view-btn"
                onClick={() => navigate(`/user/${userId}/hub-view/${hubId}/`)}
              >
                View details
              </p>
              {currentUser.hub.is_admin && (
                <Button
                  shape="round"
                  className="hub-profile-btn"
                  onClick={() => navigate(`/user/${userId}/edit-hub`)}
                >
                  Complete hub profile
                </Button>
              )}
              <CrewList />
            </div>
            <Divider
              type="vertical"
              style={{
                height: "70vh",
                width: "2.5px",
              }}
              className="v-divider divider-color"
            />
            <Divider
              style={{
                width: "90%",
                height: "2.5px",
              }}
              className="h-divider divider-color"
            />
            <AssignmentListMenu />
          </div>
        </div>
      )}
    </>
  );
};

export default HubManagement;
