import { Divider, Input } from "antd";
import HubForm from "../Forms/HubForm/HubForm";
import { useEffect, useState } from "react";
import HubSearchResult from "../HubSearchResult/HubSearchResult.component";
import { debounce } from "lodash";
import "./Hub.styles.scss";
import api from "../../api/api";

const Hub = () => {
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const debouncedSearch = debounce(async (value) => {
    setSearchQuery(value);

    try {
      const response = await api.searchHub(value);
      console.log(response);

      // Extract relevant data from the API response
      const results = response?.results;
      console.log(results);

      setSearchResults(results);
    } catch (error) {
      console.error("Error searching:", error);
    }
  }, 500);

  const handleInputChange = (e) => {
    // Trigger search on input change
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const initialSearch = async (value) => {
      try {
        const response = await api.searchHub(value);
        console.log(response);

        // Extract relevant data from the API response
        const results = response?.results;
        console.log(results);

        setSearchResults(results);
      } catch (error) {
        console.error("Error searching:", error);
      }
    };
    initialSearch("a");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hub-container">
      <div className="search-section-container">
        <h1 className="color-white" style={{ marginBottom: "2rem" }}>
          Join an Existing Hub?
        </h1>
        <div className="searchbar-container">
          <Search
            className="search-bar"
            placeholder="Find hubs here..."
            onSearch={debouncedSearch}
            onChange={handleInputChange}
          />
        </div>
        {searchResults?.length > 0 ? (
          <div className="search-result">
            <HubSearchResult searchResult={searchResults} />
          </div>
        ) : (
          searchQuery &&
          searchResults?.length === 0 && (
            <div className="search-result">
              <p className="color-white">
                No results found for "{searchQuery}"
              </p>
            </div>
          )
        )}
      </div>
      <div className="or-container">
        <h1 className="color-white">OR</h1>
        <Divider type="vertical" className="v-divider divider-color" />
        <Divider className="h-divider">OR</Divider>
      </div>
      <div className="hub-form-container">
        <h1 className="color-white" style={{ marginBottom: "2rem" }}>
          Captain your own Hub?
        </h1>
        <HubForm />
      </div>
    </div>
  );
};

export default Hub;
