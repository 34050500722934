import { Button, Checkbox, Form, Input, Radio, Slider } from "antd";
import locationSvg from "../../../assets/images/SVG/location.svg";
import LocationInput from "./LocationInput.jsx";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

export const CustomRadioGroup = ({
  options,
  form,
  name,
  onChange,
  initialValue,
  rentStatusButton,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [rentStatus, setRentStatus] = useState("not included in rent");

  const className = `radio-button-group ${
    options?.length <= 3
      ? "radio-flex"
      : options?.length > 6
      ? "radio-super-grid"
      : "radio-grid"
  }`;

  const handleRadioChange = (e) => {
    if (rentStatusButton) {
      const value = e.target.value;
      setSelectedOption(value);
      const isOptionSelected = value !== "none";
      const isIncludedInRent = rentStatus === "included in rent";

      const newValue = {
        choice: value,
        enable: isOptionSelected,
        included_in_rent: isOptionSelected && isIncludedInRent,
      };

      form.setFieldValue(name, newValue);
      // onChange(e, name);
    } else {
      onChange(e, name);
    }
  };

  const toggleRentStatus = (e) => {
    setRentStatus((prevStatus) =>
      prevStatus === "not included in rent"
        ? "included in rent"
        : "not included in rent"
    );
    if (selectedOption !== null) {
      const isOptionSelected = selectedOption !== "none";
      const isIncludedInRent = rentStatus !== "included in rent"; // because toggle changes the status after setting

      const newValue = {
        choice: selectedOption,
        enable: isOptionSelected,
        included_in_rent: isOptionSelected && isIncludedInRent,
      };

      form.setFieldValue(name, newValue);
    }
  };

  return (
    <Radio.Group
      className={className}
      onChange={handleRadioChange}
      value={
        form.getFieldValue(name)?.choice ??
        form.getFieldValue(name) ??
        initialValue
      }
    >
      {options?.map((item) => {
        const isSelected =
          selectedOption === item?.value && item?.value !== "none";
        const buttonClassName = `radio-button ${
          options?.length <= 3 ? "button-big" : "button-small"
        } ${rentStatusButton && isSelected && "adjusted-border"}`;

        const rentStatusButtonClass = `rent-status-button ${
          rentStatus === "included in rent" ? "included-border" : "no-border"
        }`;

        return (
          <div className="radio-button-container" key={item?.value}>
            <Radio.Button
              className={buttonClassName}
              key={item?.value}
              value={item?.value}
            >
              {item?.display}
            </Radio.Button>
            {rentStatusButton && isSelected && (
              <Button
                onClick={toggleRentStatus}
                className={rentStatusButtonClass}
              >
                {rentStatus}
              </Button>
            )}
          </div>
        );
      })}
    </Radio.Group>
  );
};

export const MultiSelectOptions = ({
  options,
  onChange,
  name,
  initialValue,
  form,
  rentStatusButton,
}) => {
  const [rentStatuses, setRentStatuses] = useState({});
  const [selectedOptions, setSelectedOptions] = useState(initialValue || []); // Track multiple selected options
  const className = `checkbox-group ${
    options?.length <= 3
      ? "checkbox-flex"
      : options?.length > 6
      ? "checkbox-super-grid"
      : "checkbox-grid"
  }`;

  const handleCheckboxChange = (checkedValues) => {
    // If "none" is selected, clear other selections
    if (checkedValues.includes("none")) {
      setSelectedOptions(["none"]); // Only "none" should be selected
      if (rentStatusButton) {
        // If rentStatusButton is enabled, update form value accordingly
        form?.setFieldValue(name, [
          { choice: "none", enable: true, included_in_rent: false },
        ]);
      } else {
        onChange(["none"], name); // Trigger onChange with "none"
      }
    } else {
      // If "none" was previously selected, remove it from the checked values
      const filteredValues = checkedValues.filter((value) => value !== "none");

      setSelectedOptions(filteredValues); // Update selected options

      if (rentStatusButton) {
        const updatedValues = filteredValues.map((value) => {
          const isOptionSelected = value !== "none";
          const isIncludedInRent = rentStatuses[value] === "included in rent";

          return {
            choice: value,
            enable: isOptionSelected,
            included_in_rent: isOptionSelected && isIncludedInRent,
          };
        });

        form?.setFieldValue(name, updatedValues); // Update form with new values
      } else {
        onChange(filteredValues, name); // Trigger onChange with updated values
      }
    }
  };

  const toggleRentStatus = (optionValue) => {
    // Toggle rent status for the clicked option
    setRentStatuses((prevStatuses) => ({
      ...prevStatuses,
      [optionValue]:
        prevStatuses[optionValue] === "not included in rent"
          ? "included in rent"
          : "not included in rent",
    }));

    // Update selected options with the new rent status
    const updatedOptions = selectedOptions?.map((value) => {
      const isOptionSelected = value !== "none";
      const isIncludedInRent =
        value === optionValue
          ? rentStatuses[optionValue] === "not included in rent"
          : rentStatuses[value] === "included in rent";

      return {
        choice: value,
        enable: isOptionSelected,
        included_in_rent: isOptionSelected && isIncludedInRent,
      };
    });

    form.setFieldValue(name, updatedOptions);
  };

  const currentValue =
    form.getFieldValue(name)?.map((item) => item.choice ?? item) ||
    selectedOptions;

  return (
    <Checkbox.Group
      className={className}
      // options={options}
      value={currentValue}
      onChange={handleCheckboxChange}
    >
      {options?.map((item) => {
        const isSelected =
          selectedOptions.includes(item?.value) && item?.value !== "none"; //
        const buttonClassName = `checkbox-button ${
          options?.length <= 3 ? "button-big" : "button-small"
        } ${rentStatusButton && isSelected && "adjusted-border"}`;

        const rentStatusButtonClass = `rent-status-button ${
          rentStatuses[item?.value] === "included in rent"
            ? "included-border"
            : "no-border"
        }`;

        return (
          <div key={item?.value} className="radio-button-container">
            <Checkbox className={buttonClassName} value={item?.value}>
              {item?.display}
            </Checkbox>
            {rentStatusButton && isSelected && item?.value !== "none" && (
              <Button
                onClick={() => toggleRentStatus(item?.value)}
                className={rentStatusButtonClass}
              >
                {rentStatuses[item?.value] ?? "not included in rent"}
              </Button>
            )}
          </div>
        );
      })}
    </Checkbox.Group>
  );
};

export const FormInput = ({
  label,
  name,
  initialValue,
  placeholder,
  isTextArea,
  isLocation,
  form,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        initialValue={initialValue}
        className={
          isTextArea ? "input-textArea" : isLocation ? "input-location" : ""
        }
      >
        {isTextArea ? (
          <Input.TextArea
            autoSize={{
              minRows: 5,
            }}
            showCount
            maxLength={150}
            placeholder={placeholder}
          />
        ) : isLocation ? (
          <LocationInput
            form={form}
            placeholder={placeholder}
            isLocation={isLocation}
            locationSvg={locationSvg}
          />
        ) : (
          <Input placeholder={placeholder} />
        )}
      </Form.Item>
    </>
  );
};

export const CustomSlider = ({
  marks,
  initialValue,
  max,
  onChange,
  form,
  name,
}) => {
  return (
    <Slider
      onChange={(e) => onChange(e, name)}
      max={max}
      min={0}
      className="point-slider custom-slider"
      marks={marks}
      value={form?.getFieldValue(name) ?? initialValue}
    />
  );
};

export const AddInputs = ({
  form,
  name,
  title,
  placeHolder1,
  placeHolder2,
  placeHolder3,
  placeHolder,
}) => {
  return (
    <>
      <p>{title}</p>
      <Form.List name={name} initialValue={["", "", ""]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div className="removable-item" key={key}>
                <Form.Item
                  {...restField}
                  className="place-input additional"
                  name={name}
                  rules={[
                    {
                      required: true,
                      message: "Please fill any field",
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      index === 0
                        ? placeHolder1
                        : index === 1
                        ? placeHolder2
                        : index === 2
                        ? placeHolder3
                        : placeHolder
                    }
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </div>
            ))}
            {fields.length < 5 && (
              <Form.Item>
                <Button
                  className="add-input-btn"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                ></Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </>
  );
};
