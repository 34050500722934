import { Avatar, Button, Divider, message, Modal, Progress } from "antd";
import Cookies from "js-cookie";
import api from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import { useUserContext } from "../../context/UserContext";
import FeedbackForm from "../Forms/FeedbackFrom/FeedbackFrom.component";
import logout from "../../assets/images/SVG/logout.svg";
import theme from "../../assets/images/SVG/theme.svg";
import deleteIcon from "../../assets/images/SVG/delete.svg";
import arrow from "../../assets/images/SVG/ArrowRight.svg";
import leavehub from "../../assets/images/SVG/Leavehub.svg";
import { InboxOutlined } from "@ant-design/icons";
import "./Settings.styles.scss";
import UserProfileDetailsComponent from "../UserProfileDetails/UserProfileDetails.component";
import { useState } from "react";

const Settings = () => {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const { userId } = useParams();
  const { currentUser } = useUserContext();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hubId = currentUser?.hub?.hub?.hub_id;
  const hubData = queryClient.getQueryData(["hubDetails", `${hubId}`]);
  const crewMembers = queryClient.getQueryData(["hubCrew", `${hubId}`]);
  const profilePicture = currentUser?.profile_picture_url;
  const isLastUser = crewMembers?.length === 1;
  const isLastAdmin = crewMembers?.length > 1 && hubData?.admins?.length === 1;
  const { data: profileDetails, isLoading } = useQuery(
    "profileDetails",
    async () => await api.getProfileDetails(userId)
  );

  const ifEveryChoiceIsNull = profileDetails?.profile_details.every(
    (detail) => detail[Object.keys(detail)[0]].choice === null
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const logoutMutation = useMutation(() => api.logoutUser(), {
    onSuccess: () => {
      Cookies.remove("csrftoken");
      Cookies.remove("sessionid");

      console.log("Logout successful!");
      navigate(`/login`);
      queryClient.setQueryData("currentUser", null);
    },
    onError: (error) => {
      console.error("Logout error:", error);
    },
  });

  const leaveHubMutation = useMutation(() => api.leaveHub(), {
    onSuccess: () => {
      queryClient.invalidateQueries("currentUser");
      message.success("Successfully leaved from the hub");
    },
    onError: (error) => {
      console.error("error in leaving hub:", error);
      message.error(error?.message);
    },
  });

  const onLogout = async () => {
    try {
      await logoutMutation.mutateAsync();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const leaveHub = async () => {
    try {
      await leaveHubMutation.mutateAsync();
    } catch (error) {
      console.error("error in leaving hub:", error);
    }
  };
  const showConfirmation = (content, onConfirm) => {
    confirm({
      title: "Confirmation",
      content: content,
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk() {
        onConfirm();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleLogoutConfirmation = () => {
    showConfirmation("Are you sure that you want to logout?", onLogout);
  };

  const handleLeaveHubConfirmation = () => {
    const message = isLastUser
      ? `You are the last member of ${currentUser?.hub?.hub?.hub_name}. Leaving will delete all assignments and the hub. Continue?`
      : isLastAdmin
      ? `Are you sure? You are the only admin of ${currentUser?.hub?.hub?.hub_name}. Assign someone else as admin before leaving.`
      : "Are you sure you wish to leave this hub?";
    showConfirmation(message, leaveHub);
  };

  const completeionPercentage = Math.round(
    profileDetails?.completion_percentage
  );
  return (
    <div className="settings-container">
      <div className="edit-profile">
        <Avatar
          size={64}
          src={
            profilePicture === null || profilePicture?.endsWith("default.jpg")
              ? profileAvatar
              : currentUser?.profile_picture_url
          }
        />
        <span className="user-name">
          {currentUser?.first_name} {currentUser?.last_name}
        </span>
        <span className="user-email">{currentUser?.email}</span>
        {typeof currentUser?.email_verification_check === "number" && (
          <span className="verification-check-content">{`Your email address is unverified. Please verify your email within ${currentUser?.email_verification_check} days to continue using your account.`}</span>
        )}
        {!ifEveryChoiceIsNull && (
          <UserProfileDetailsComponent
            userId={userId}
            details={profileDetails?.profile_details}
          />
        )}
        {profileDetails?.completion_percentage < 100 && (
          <Progress
            percent={completeionPercentage}
            percentPosition={{
              align: "center",
              type: "inner",
            }}
            size={["100%", 15]}
          />
        )}
        {profileDetails?.completion_percentage === 100 ? (
          <Button shape="round" className="edit-profile-btn">
            Edit your profile
          </Button>
        ) : (
          <Button
            shape="round"
            className="edit-profile-btn"
            onClick={() =>
              navigate(`/user/${currentUser?.crew_id}/edit-profile`)
            }
          >
            Complete your profile
          </Button>
        )}

        <FeedbackForm />
      </div>
      <Divider
        type="vertical"
        className="v-divider divider-color"
        style={{
          height: "70vh",
          width: "1.5px",
        }}
      />
      <div className="settings">
        <h1 className="title-name">Settings</h1>
        <Button
          className="card-btn action-btn"
          icon={<InboxOutlined className="inbox" />}
          onClick={() => {
            navigate(
              `/user/${currentUser?.crew_id}/hub/invite-request-management`
            );
          }}
        >
          Invites & Requests
        </Button>
        {currentUser?.hub && (
          <Button
            className="card-btn action-btn"
            onClick={handleLeaveHubConfirmation}
            loading={leaveHubMutation.isLoading}
          >
            <img className="action" src={leavehub} alt="leavehub" />
            {`Leave ${isLastUser ? "& delete" : ""} Hub (${
              currentUser?.hub?.hub?.hub_name?.charAt(0).toUpperCase() +
              currentUser?.hub?.hub?.hub_name?.slice(1)
            })`}
          </Button>
        )}
        <Button className="card-btn theme-btn">
          <div className="Theme">
            <img className="theme" src={theme} alt="theme" />
            Theme
          </div>
          <div className="Dark">
            <span>Dark</span>
            <img className="arrow" src={arrow} alt="arrow" />
          </div>
        </Button>
        <Button
          className="card-btn action-btn"
          onClick={() => {
            isLastAdmin && currentUser?.hub?.is_admin
              ? showModal()
              : navigate(`/user/${currentUser?.crew_id}/hub/delete-user/`);
          }}
        >
          <img className="action" src={deleteIcon} alt="delete" />
          Delete User Data
        </Button>
        <Modal
          className="confirm-modal"
          open={isModalOpen}
          onOk={handleCancel}
          closeIcon={false}
          cancelButtonProps={{ className: "display-none" }}
          okButtonProps={{ className: "ok-button" }}
        >
          <p>You should leave hub before deleting data</p>
        </Modal>
        <Button
          className="card-btn action-btn"
          onClick={handleLogoutConfirmation}
          loading={logoutMutation.isLoading}
        >
          <img className="action" src={logout} alt="logout" />
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Settings;
