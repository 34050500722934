import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Dropdown } from "antd";
import logo from "../../../assets/images/SVG/LogoWithName.svg";
import profileAvatar from "../../../assets/images/SVG/avatar-blue.svg";
import { ArrowLeftOutlined, MenuOutlined } from "@ant-design/icons";
import { Outlet } from "react-router";
import { useQueryClient } from "react-query";
import MenuItems from "./MenuItems.jsx";
import "./UserNavigation.styles.scss";
import Notification from "../../Notification/Notification.component";
import { useUserContext } from "../../../context/UserContext";
import { getTokens, onMessageListener } from "../../../api/firebase";
import UserProfile from "../../UserProfile/UserProfile.component";
import { auth } from "../../../api/firebase.js";
import api from "../../../api/api.js";

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const queryClient = useQueryClient();
  const user = auth?.currentUser;
  const emailVerified = user?.emailVerified;

  const { currentUser } = useUserContext();

  const items = [
    {
      label: <UserProfile currentUser={currentUser} />,
      key: "0",
    },
  ];

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      setTokenFound(true);
    })
    .catch((err) => {
      console.error("Error in onMessageListener:", err);
    });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const userCrewId = currentUser?.crew_id || null;
  const userHubId = currentUser?.hub?.hub?.hub_id || null;

  useEffect(() => {
    getTokens(setTokenFound);
    if (
      emailVerified &&
      typeof currentUser?.email_verification_check === "number"
    ) {
      api.verifyEmail();
      queryClient.invalidateQueries("currentUser");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="usernav-container">
        <div className="logo-container">
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
        </div>
        <div className="menu-items">
          <MenuItems userCrewId={userCrewId} userHubId={userHubId} />
          <Drawer
            className="nav-sidebar"
            placement="left"
            onClose={onClose}
            open={visible}
            closeIcon={<ArrowLeftOutlined />}
          >
            <MenuItems
              userCrewId={userCrewId}
              userHubId={userHubId}
              onClose={onClose}
            />
          </Drawer>
        </div>
        <div className="nav-icons">
          <Notification />
          <Dropdown
            overlayClassName="profile-dropdown"
            menu={{ items }}
            trigger={["click"]}
          >
            <Avatar
              className="nav-avatar"
              src={
                currentUser?.profile_picture_url === null ||
                currentUser?.profile_picture_url.endsWith("default.jpg")
                  ? profileAvatar
                  : currentUser?.profile_picture_url
              }
              size={48}
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
          <MenuOutlined onClick={showDrawer} className="display" />
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
