import { Card } from "antd";
import "./NotificationItem.styles.scss";

const NotificationItem = ({ notification, onClick }) => {
  return (
    <Card className="notification-item" hoverable onClick={onClick}>
      <img
        src={notification?.svg_file_url}
        alt="icon"
        width="30px"
        height="30px"
      />
      <p className="notification-content">{notification?.message}</p>
    </Card>
  );
};

export default NotificationItem;
