import { Progress } from "antd";
import { useEffect, useState } from "react";
import AssignmenStatustDetails from "../AssignmentStatusDetails/AssignmentStatusDetails.component";
import "./GoalSection.styles.scss";
import { useQueryClient } from "react-query";

const GoalSection = () => {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData("currentUser");
  const crewPoint = currentUser?.score_info?.crew_points;
  const startPoint = currentUser?.score_info?.level_starting_points;
  const endPoint = currentUser?.score_info?.level_ending_points;

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPercent(() => (crewPoint / endPoint) * 100);
    }, 1000);
  }, [crewPoint, endPoint]);

  return (
    <div className="goal-and-taskStatusDetails">
      <div className="goal-section">
        <div className="dotted1"></div>
        <div className="dotted2"></div>
        <div className="dotted3"></div>
        <div className="progress-container">
          <Progress
            type="dashboard"
            percent={percent}
            gapDegree={80}
            strokeWidth={4}
            showInfo={false}
          />
          <div className="level-container">
            <h2 className="level">
              Level {currentUser?.score_info?.Crew_level}
            </h2>
            <p className="level-name">Professional</p>
          </div>
          <div className="points-progress">
            <span className="start-point">{startPoint}</span>
            <span className="end-point">
              {crewPoint}/{endPoint}
            </span>
          </div>
        </div>
      </div>
      <AssignmenStatustDetails currentUser={currentUser} />
    </div>
  );
};

export default GoalSection;
