import React, { useState } from "react";
import "./CrewProfileView.styles.scss";
import { useQuery } from "react-query";
import api from "../../api/api";
import { Avatar, Button, Empty, Spin } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import { useParams } from "react-router";
import MyGoogleMap from "../GoogleMap/GoogleMap";

const CrewProfileView = () => {
  const { crewId } = useParams();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 51.505, lng: -0.09 });
  const { data: userProfileDetails, isLoading } = useQuery(
    [`userProfileDetails-${crewId}`, crewId],
    async () => await api.getProfileDetails(crewId)
  );
  console.log({ userProfileDetails });

  const mapContainerStyle = {
    width: "250px",
    height: "200px",
  };

  const ifEveryChoiceIsNull = userProfileDetails?.profile_details?.every(
    (detail) => detail[Object.keys(detail)[0]].choice === null
  );

  return (
    <div className="crewProfile-container">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          <div
            className={`profile-details ${
              ifEveryChoiceIsNull && "no-data-class"
            }`}
          >
            {/* <div className="avatar-container"> */}
            {/* <Progress type="dashboard" percent={60} size={[82.5, 20]} /> */}
            <Avatar size={70} src={profileAvatar} />
            {/* </div> */}
            <h1 className="name">
              {userProfileDetails?.first_name} {userProfileDetails?.last_name}
            </h1>
            <span className="level">Level 3: Professional</span>
            {userProfileDetails?.profile_description && (
              <div className="item-container">
                <span className="title">About me</span>
                <span className="description">
                  {userProfileDetails?.profile_description}
                </span>
              </div>
            )}

            {ifEveryChoiceIsNull ? (
              <Empty
                // image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No additional information available"
              />
            ) : (
              <>
                <div className="item-container">
                  <span className="title">Basic Information</span>
                  <div className="item">
                    <span className="label">Gender</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[0]?.gender?.text}
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[0]?.gender?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Age</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[1]?.age?.text}
                      </span>
                      <img
                        src={userProfileDetails?.profile_details[1]?.age?.icon}
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Occupational Status</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[2]
                            ?.occupational_status?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[2]
                            ?.occupational_status?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="item-container">
                  <span className="title">Lifestyle</span>
                  <div className="item">
                    <span className="label">Smoking</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[3]?.smoker?.text}
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[3]?.smoker?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Drinking</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[4]?.alcohol?.text}
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[4]?.alcohol?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Cooking frequency</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[6]?.cook?.text}
                      </span>
                      <img
                        src={userProfileDetails?.profile_details[6]?.cook?.icon}
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Sociability</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[5]
                            ?.social_interaction?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[5]
                            ?.social_interaction?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="item-container">
                  <span className="title">Home preferences</span>
                  <div className="item">
                    <span className="label">Pet policy</span>
                    <div className="value-container">
                      <span className="value">
                        {userProfileDetails?.profile_details[7]?.pets?.text}
                      </span>
                      <img
                        src={userProfileDetails?.profile_details[7]?.pets?.icon}
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Noise tolerance</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[9]
                            ?.noise_sensitivity?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[9]
                            ?.noise_sensitivity?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Tidiness</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[8]?.cleanliness
                            ?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[8]?.cleanliness
                            ?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="item-container">
                  <span className="title">Work preferences</span>
                  <div className="item">
                    <span className="label">W.F.H frequency</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[10]
                            ?.work_from_home?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[10]
                            ?.work_from_home?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <span className="label">Shift times</span>
                    <div className="value-container">
                      <span className="value">
                        {
                          userProfileDetails?.profile_details[11]?.shift_time
                            ?.text
                        }
                      </span>
                      <img
                        src={
                          userProfileDetails?.profile_details[11]?.shift_time
                            ?.icon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="hub-details">
            <MyGoogleMap
              position={currentPosition}
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              mapClassName="crewProfile-map"
            />
            <h1 className="hub-name">Tharavad</h1>
            <span className="hub-location">#34. HSR layout, Bangalore. </span>
            <Button className="request-btn" shape="round">
              Request to join hub
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CrewProfileView;
