import "./WeekdaySelector.styles.scss";

const WeekdaySelector = ({
  selectedDays,
  setSelectedDays,
  daysOfWeek,
  // repeatTill,
  // dayNamesMap,
}) => {
  const handleWeekdayChange = (dayName) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(dayName)) {
        return prevSelectedDays.filter((day) => day !== dayName);
      } else {
        return [...prevSelectedDays, dayName];
      }
    });
  };

  // // const selectedDaysText = selectedDays?.join(", "); // Join selected days with a comma
  // function generateSentence(selectedDays, repeatTill, dayNamesMap) {
  //   let sentence = "Repeats every ";

  //   if (selectedDays.length === 0) {
  //     return ""; // No selected days, return empty string
  //   }

  //   if (selectedDays.length >= 2) {
  //     const abbreviatedDays = selectedDays.map((day) => dayNamesMap[day]);

  //     if (selectedDays.length === 2) {
  //       // If there are only two selected days, use '&' between them
  //       sentence += abbreviatedDays.join(" & ");
  //     } else {
  //       // If there are more than two selected days, use commas between them and '&' between the last two
  //       const formattedDays = abbreviatedDays.slice(0, -1).join(", ");
  //       const lastDay = abbreviatedDays[abbreviatedDays.length - 1];
  //       sentence += `${formattedDays}, & ${lastDay}`;
  //     }

  //     if (repeatTill) {
  //       sentence += ` until ${repeatTill}`;
  //     }
  //   } else if (selectedDays.length === 1) {
  //     sentence += dayNamesMap[selectedDays[0]];
  //     if (repeatTill) {
  //       sentence += ` until ${repeatTill}`;
  //     }
  //   }

  //   return sentence;
  // }

  // const selectedDaysText = generateSentence(
  //   selectedDays,
  //   repeatTill,
  //   dayNamesMap
  // );

  return (
    <div className="weekday-container">
      <div className="weekday-selector">
        {daysOfWeek.map((day, index) => (
          <button
            type="button"
            key={index}
            className={`weekday-button ${
              selectedDays?.includes(day) ? "selected" : ""
            }`}
            onClick={() => {
              handleWeekdayChange(day); // Pass the day name to the handler
            }}
          >
            {day}
          </button>
        ))}
      </div>
      {/* {selectedDays?.length > 0 && (
        <p className="selected-days-text">{selectedDaysText}</p>
      )} */}
    </div>
  );
};

export default WeekdaySelector;
