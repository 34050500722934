import { Avatar, Button, Card, Dropdown, Progress } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import dayjs from "dayjs";
import editIcon from "../../assets/images/SVG/edit.svg";
import "./AssignmentDetailsPage.styles.scss";

const AssignmentDetailsPage = ({
  assignment,
  onEditTask,
  showDoneConfirm,
  showDeleteConfirm,
}) => {
  const completedAssignment = assignment?.status === "Completed";
  const calculatePercentage = (startDate, toDoDate) => {
    const today = new Date();
    const startDateTime = new Date(startDate).getTime();
    const toDoDateTime = new Date(toDoDate).getTime();
    const todayTime = today.getTime();

    const totalDays = toDoDateTime - startDateTime;
    const remainingDays = toDoDateTime - todayTime;

    const percentage = ((totalDays - remainingDays) / totalDays) * 100;

    return percentage > 100 ? 100 : percentage;
  };

  const percent = calculatePercentage(
    assignment?.start_date,
    assignment?.to_do_on
  );

  const toDoDate = dayjs(assignment?.to_do_on).format("DD-MMM-YYYY");
  const repeatTill = dayjs(assignment?.repeat_till).format("DD-MMM-YYYY");
  const weekdays = assignment?.week_days;
  const formattedWeekdays = weekdays?.join(", ").replace(/,([^,]*)$/, " & $1");
  const description = assignment?.assignment_description;
  const capitalizedDescription =
    description?.charAt(0).toUpperCase() + description?.slice(1);

  return (
    <>
      <div className="assignmenDetailsPage-container">
        <Card
          className="assignmentDetails-card"
          extra={
            assignment?.repeat_id ? (
              <Dropdown
                overlayClassName="dropdown"
                menu={{
                  items: [
                    {
                      key: "editSeries",
                      label: (
                        <span
                          onClick={() => onEditTask(assignment, "editSeries")}
                        >
                          Edit Series
                        </span>
                      ),
                    },
                    {
                      key: "editSingle",
                      label: (
                        <span
                          onClick={() => onEditTask(assignment, "editSingle")}
                        >
                          Edit Occurance
                        </span>
                      ),
                    },
                  ],
                }}
                trigger={["click"]}
                arrow
              >
                <Button className="edit-btn-small">
                  Edit
                  <img height="9px" width="9px" src={editIcon} alt="edit-btn" />
                </Button>
              </Dropdown>
            ) : (
              <Button
                className={`edit-btn-small  ${
                  completedAssignment && "btn-disbaled"
                }`}
                onClick={
                  !completedAssignment
                    ? () => onEditTask(assignment, "editSingle")
                    : null
                }
              >
                Edit
                <img height="9px" width="9px" src={editIcon} alt="edit-btn" />
              </Button>
            )
          }
        >
          <div className="title-container">
            <div className="emoji">{assignment?.assignment_emoji_grouping}</div>
            <span className="assignment_name">
              {assignment?.assignment_name}
            </span>
          </div>
          <span className="points">{assignment?.assignment_points} points</span>
          <Progress percent={percent} showInfo={false} />
          <div className="itemContainer">
            <span className="item-title">Description</span>
            <span className="item-content">{capitalizedDescription}</span>
          </div>
          <div className="itemContainer">
            <span className="item-title">Deadline</span>
            <span className="item-content">{toDoDate}</span>
          </div>
          {assignment?.repeat_id && (
            <div className="itemContainer">
              <span className="item-title">Repeats</span>
              <span className="item-content">{`Every ${formattedWeekdays} until ${repeatTill}`}</span>
            </div>
          )}
          <div className="itemContainer">
            <span className="item-title">Assigned crew</span>
            <div className="assignedCrew-cards">
              {assignment?.assigned_to?.map((crew) => (
                <Card key={crew?.crew_id} className="assignedCrew-card">
                  <Avatar
                    size={48}
                    src={
                      crew?.profile_picture_url === null ||
                      crew?.profile_picture_url.endsWith("default.jpg")
                        ? profileAvatar
                        : crew?.profile_picture_url
                    }
                  />
                  <div className="name-level">
                    <span className="crew-name">{crew?.full_name}</span>
                    <span className="crew-level">
                      {`Level ${crew?.crew_level}`}
                    </span>
                  </div>
                </Card>
              ))}
            </div>
            <Button
              className={`${completedAssignment && "btn-primary-disabled"}`}
              style={{ marginBottom: "0.8rem" }}
              type="primary"
              shape="round"
              onClick={
                !completedAssignment ? () => showDoneConfirm(assignment) : null
              }
            >
              Mark as completed
            </Button>

            {assignment?.repeat_id ? (
              <Dropdown
                overlayClassName="dropdown"
                menu={{
                  // TODO : Move items to seperate variable.
                  items: [
                    {
                      key: "deleteSeries",
                      label: (
                        <span
                          onClick={() =>
                            showDeleteConfirm(assignment, "deleteSeries")
                          }
                        >
                          Delete Series
                        </span>
                      ),
                    },
                    {
                      key: "deleteSingle",
                      label: (
                        <span
                          onClick={() =>
                            showDeleteConfirm(assignment, "deleteSingle")
                          }
                        >
                          Delete Occurance
                        </span>
                      ),
                    },
                  ],
                }}
                trigger={["click"]}
                placement="top"
                arrow
              >
                <Button className="btn-delete" shape="round">
                  Delete Assignment
                </Button>
              </Dropdown>
            ) : (
              <Button
                className={`btn-delete ${
                  completedAssignment && "btn-delete-disabled"
                }`}
                shape="round"
                onClick={
                  !completedAssignment
                    ? () => showDeleteConfirm(assignment, "deleteSingle")
                    : null
                }
              >
                Delete Assignment
              </Button>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default AssignmentDetailsPage;
