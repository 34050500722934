import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import api from "../api/api";
// ... (other imports)
const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
};

const UserProvider = ({ children }) => {
  const { data: currentUser, isLoading } = useQuery(
    "currentUser",
    api.getCurrentUser,
    {
      onError: (error) => {
        console.error("Error fetching current user:", error);
      },
    }
  );

  // const logoutUser = async () => {
  //   // Logic to logout user (if needed)
  //   // Example: await logoutUser();
  //   queryClient.invalidateQueries("currentUser");
  // };

  // if (isLoading) {
  //   // Optional: Show loading indicator while fetching data
  //   return <p>Loading...</p>;
  // }

  return (
    <UserContext.Provider value={{ currentUser, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
