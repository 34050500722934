import { useLocation } from "react-router";
import "./MenuItems.scss";
import { Link } from "react-router-dom";

const MenuItems = ({ userHubId, userCrewId, onClose }) => {
  const location = useLocation();
  const windowWidth = window.innerWidth;

  const navItems = [
    {
      label: "Dashboard",
      path: `/user/${userCrewId}`,
      visible: !!userHubId,
    },
    {
      label: "My Hub",
      path: userHubId
        ? `/user/${userCrewId}/hub/${userHubId}`
        : `/user/${userCrewId}/hub`,
      visible: true,
    },
    {
      label: "Settings",
      path: `/user/${userCrewId}/settings`,
      visible: true,
    },
  ];

  const navItemsWidth = userHubId ? 300 : 150;

  return (
    <ul
      className="nav-items nav-items-vertical"
      style={{ width: `${navItemsWidth}px` }}
    >
      {navItems
        .filter((item) => item?.visible)
        .map((item, index) => (
          <li
            key={index}
            className={`nav-item ${
              location.pathname === item.path ? "selected" : ""
            }`}
            onClick={() => {
              windowWidth <= 766 && onClose();
            }}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
    </ul>
  );
};

export default MenuItems;
