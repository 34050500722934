import coverImg from "../../assets/images/cover.png";
import logo from "../../assets/images/SVG/LogoWithName.svg";
import { Divider } from "antd";
import RegisterForm from "../Forms/RegisterForm/RegisterForm";
import "./Register.styles.scss";
import { useState } from "react";

const Register = () => {
  const [verificationPage, setVerificationPage] = useState(false);

  return (
    <div className="container">
      <div className="image-container">
        <img className="cover-image" src={coverImg} alt="cover" />
      </div>
      <Divider
        className="vd"
        type="vertical"
        style={{ height: "80vh", backgroundColor: "#4E4E61", width: "2.5px" }}
      />
      <div className={`form-container ${verificationPage ? "h70" : ""}`}>
        <img
          src={logo}
          className="logo"
          alt="logo"
          width="200px"
          height="40px"
        />
        <RegisterForm
          verificationPage={verificationPage}
          setVerificationPage={setVerificationPage}
        />
      </div>
    </div>
  );
};

export default Register;
