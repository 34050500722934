import { Avatar, Button, Card, Drawer, Dropdown, Form, Progress } from "antd";
import { ArrowRightOutlined, StarFilled } from "@ant-design/icons";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import { useState } from "react";
import deleteIcon from "../../assets/images/SVG/delete.svg";
import editIcon from "../../assets/images/SVG/edit.svg";
import doneIcon from "../../assets/images/SVG/done.svg";
import { useLocation, useParams } from "react-router";
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm.component";
import { dayNamesMap } from "../../variables";
import "./AssignmentDetailsCard.styles.scss";
import AssignmentDetailsPage from "../AssignmentDetailsPage/AssignmentDetailsPage.component";

const AssignmentDetailsCard = ({
  assignment,
  onEditTask,
  editTaskData,
  isEditingSeries,
  formattedDate,
  showDeleteConfirm,
  showDoneConfirm,
  compressed,
  setCompressed,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { hubId, userId } = useParams();
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  // const [compressed, setCompressed] = useState(false);
  // const [editTaskData, setEditTaskData] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [showWeekdaySelector, setShowWeekdaySelector] = useState(false);
  // const [isEditingSeries, setIsEditingSeries] = useState(false); // State for isEditingSeries
  const [className, setClassName] = useState(false);
  const completedAssignment = assignment?.status === "Completed";
  const isUserInCorrectPath = location.pathname.includes(
    `user/${userId}/hub/${hubId}`
  );

  const toggleCompress = () => {
    setCompressed(!compressed);
  };

  const handleCardClick = () => {
    if (isUserInCorrectPath && assignment?.status !== "Completed") {
      toggleCompress();
    } else {
      setClassName(true);
    }
  };

  const handleMoreDetailsClick = (event) => {
    event.stopPropagation();
    setDetailsDrawerVisible(true);
  };

  // Calculate percentage based on points
  const calculatePercentage = (startDate, toDoDate) => {
    const today = new Date();
    const startDateTime = new Date(startDate).getTime();
    const toDoDateTime = new Date(toDoDate).getTime();
    const todayTime = today.getTime();

    const totalDays = toDoDateTime - startDateTime;
    const remainingDays = toDoDateTime - todayTime;

    const percentage = ((totalDays - remainingDays) / totalDays) * 100;

    return percentage > 100 ? 100 : percentage;
  };

  const percent = calculatePercentage(
    assignment.start_date,
    assignment.to_do_on
  );

  return (
    <div className="assignmentCard-container">
      <Card
        key={assignment.assigment_id}
        style={{ marginRight: `${compressed ? "3px" : "0px"}` }}
        className={`assignment-card ${compressed ? "compressed" : ""}`}
        actions={[
          <Progress percent={percent} showInfo={false} />,
          <div
            className={`more-details ${
              isUserInCorrectPath ? "" : "displayhidden"
            }`}
            onClick={handleMoreDetailsClick}
          >
            <span>More details</span>
          </div>,
          <Drawer
            className="drawer-right"
            placement="right"
            onClose={(event) => {
              event.stopPropagation();
              setDetailsDrawerVisible(false);
              setCompressed(false);
            }}
            open={detailsDrawerVisible}
            closeIcon={false}
          >
            <AssignmentDetailsPage
              assignment={assignment}
              onEditTask={onEditTask}
              showDeleteConfirm={showDeleteConfirm}
              showDoneConfirm={showDoneConfirm}
            />
          </Drawer>,
        ]}
        onClick={handleCardClick}
      >
        <div className="left-section">
          <span style={{ fontSize: "2rem" }}>
            {assignment?.assignment_emoji_grouping}
          </span>
          <div className="name-point">
            <p className="name color-white">{assignment?.assignment_name}</p>
            <div className="point-section">
              <StarFilled className="star" />
              <p className="point color-white">{`${assignment?.assignment_points} points`}</p>
            </div>
          </div>
        </div>
        <div className={`right-section ${compressed ? "hide" : ""}`}>
          <p className="date color-white">{formattedDate}</p>
          <div className="assigned_to">
            <Avatar.Group
              max={{
                count: 2,
                popover: {
                  trigger: "click",
                },
                style: {
                  cursor: "pointer",
                },
              }}
              size={24}
            >
              {assignment?.assigned_to?.map((crew) => (
                <Avatar
                  key={crew?.crew_id}
                  className={
                    assignment?.assigned_to?.length > 1 ? "marginSide" : ""
                  }
                  src={
                    crew?.profile_picture_url === null ||
                    crew?.profile_picture_url.endsWith("default.jpg")
                      ? profileAvatar
                      : crew?.profile_picture_url
                  }
                />
              ))}
            </Avatar.Group>
          </div>
        </div>
      </Card>
      <div
        className={`buttons ${compressed ? "show" : "hide"} ${
          className ? "displayNone" : ""
        }`}
      >
        {assignment?.repeat_id ? (
          <Dropdown
            overlayClassName="dropdown"
            menu={{
              items: [
                {
                  key: "editSeries",
                  label: (
                    <span onClick={() => onEditTask(assignment, "editSeries")}>
                      Edit Series
                    </span>
                  ),
                },
                {
                  key: "editSingle",
                  label: (
                    <span onClick={() => onEditTask(assignment, "editSingle")}>
                      Edit Occurance
                    </span>
                  ),
                },
              ],
            }}
            trigger={["click"]}
            arrow
          >
            <Button className="button blue">
              <img height="20px" weight="20px" src={editIcon} alt="edit" />
            </Button>
          </Dropdown>
        ) : (
          <Button
            className={`button blue ${completedAssignment && "btn-disbaled"}`}
            onClick={
              !completedAssignment
                ? () => onEditTask(assignment, "editSingle")
                : null
            }
          >
            <img height="20px" weight="20px" src={editIcon} alt="edit" />
          </Button>
        )}

        <Drawer
          title="Edit Assignment"
          className="drawer-right edit-assignment-drawer"
          placement="right"
          onClose={(event) => {
            event.stopPropagation();
            setEditDrawerVisible(false);
            setCompressed(false);
          }}
          open={editDrawerVisible}
          closeIcon={<ArrowRightOutlined />}
        >
          <AssignmentForm
            form={form}
            isEdit={true}
            initialValues={editTaskData}
            setVisible={setEditDrawerVisible}
            isEditingSeries={isEditingSeries}
            setSelectedDays={setSelectedDays}
            selectedDays={selectedDays}
            dayNamesMap={dayNamesMap}
            showWeekdaySelector={showWeekdaySelector}
            setShowWeekdaySelector={setShowWeekdaySelector}
            setCompressed={setCompressed}
          />
        </Drawer>

        {assignment?.repeat_id ? (
          <Dropdown
            overlayClassName="dropdown"
            menu={{
              // TODO : Move items to seperate variable.
              items: [
                {
                  key: "deleteSeries",
                  label: (
                    <span
                      onClick={() =>
                        showDeleteConfirm(assignment, "deleteSeries")
                      }
                    >
                      Delete Series
                    </span>
                  ),
                },
                {
                  key: "deleteSingle",
                  label: (
                    <span
                      onClick={() =>
                        showDeleteConfirm(assignment, "deleteSingle")
                      }
                    >
                      Delete Occurance
                    </span>
                  ),
                },
              ],
            }}
            trigger={["click"]}
            // placement="bottom"
            arrow
          >
            <Button className="button red">
              <img height="25px" weight="25px" src={deleteIcon} alt="delete" />
            </Button>
          </Dropdown>
        ) : (
          <Button
            className="button red "
            onClick={() => showDeleteConfirm(assignment, "deleteSingle")}
          >
            <img height="25px" weight="25px" src={deleteIcon} alt="delete" />
          </Button>
        )}

        <Button
          className={`button green ${completedAssignment && "btn-disbaled"}`}
          onClick={
            !completedAssignment ? () => showDoneConfirm(assignment) : null
          }
        >
          <img height="15px" weight="15px" src={doneIcon} alt="done" />
        </Button>
      </div>
    </div>
  );
};

export default AssignmentDetailsCard;
