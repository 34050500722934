import { Divider } from "antd";
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails.component";
import "./DashBoard.styles.scss";
import GoalSection from "../GoalSection/GoalSection.component";
import { useUserContext } from "../../context/UserContext";

const DashBoard = () => {
  const { currentUser } = useUserContext();
  const currentUserAssignments = currentUser?.to_do_assignments;

  return (
    <div className="dashboard-container">
      <h1 className="page-heading">Dashboard</h1>
      <div className="tasks-goalsection">
        <div className="task-list">
          <div className="task-type-heading">
            <span>My Tasks</span>
          </div>
          <div className="assignmentDetails">
            <AssignmentDetails assignments={currentUserAssignments} />
          </div>
        </div>
        <Divider type="vertical" className="v-divider divider-color" />
        <Divider className="h-divider divider-color" />
        <div className="goal-container">
          <GoalSection />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
