import { Button, Form, Input, Modal, message } from "antd";
import api from "../../../api/api.js";
import "./FeedbackFrom.styles.scss";
import { useMutation } from "react-query";
import { useState } from "react";

const FeedbackForm = () => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [showSuccess, setShowSuccess] = useState(false);

  const feedbackMutation = useMutation(
    async (values) => {
      await api.submitFeedback(values);
    },
    {
      onSuccess: () => {
        setShowSuccess(true);
      },
      onError: () => {
        message.error("Error submitting feedback");
      },
    }
  );

  const onFinish = (values) => {
    try {
      const formValues = {
        ...values,
        feedback_for: "WEB",
        feedback_status: "New",
      };
      feedbackMutation.mutate(formValues);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const showConfirm = (values) => {
    confirm({
      title: "Confirmation",
      content: "Are you sure you want to submit this feedback?",
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk() {
        onFinish(values);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        showConfirm(values);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  return (
    <div className="feedback-container">
      {showSuccess ? (
        <div className="success-container">
          <span className="success-title">Feedback submitted successfully</span>
          <Button
            className="fd-btn"
            onClick={() => {
              form.resetFields();
              setShowSuccess(false);
            }}
          >
            Submit another feedback
          </Button>
        </div>
      ) : (
        <>
          <span className="feedback-title">
            Your feedback is important to us.
          </span>
          <Form
            form={form}
            // onFinish={onFinish}
            className="feedback-form"
            layout="vertical"
            requiredMark={false}
          >
            <div className="form-input-box">
              <Form.Item
                label="How can we improve?"
                name="feedback_text"
                rules={[
                  { required: true, message: "Please write a feedback" },
                  {
                    max: 100,
                    message: "Feedback must be less than 100 characters !",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Please provide a detailed description of how you’d like us to serve you better."
                  autoSize
                />
              </Form.Item>
            </div>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              className="feedback-submit-btn "
              loading={feedbackMutation.isLoading}
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </Form>
        </>
      )}
    </div>
  );
};

export default FeedbackForm;
