import { Avatar, Button, Dropdown } from "antd";
import users from "../../assets/images/SVG/users.svg";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import "./CrewListDropDown.styles.scss";
import UserProfileDetailsComponent from "../UserProfileDetails/UserProfileDetails.component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

const CrewListDropDown = ({ crewList, hubData, currentUser }) => {
  const [selectedCrewId, setSelectedCrewId] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [clickTimeout, setClickTimeout] = useState(null);
  const adminIds = hubData?.admins?.map((admin) => admin.admin_id);
  // Check if crewList is an array before mapping over it
  const crewListToMap = Array.isArray(crewList) ? crewList : [];

  // Sort crew members based on whether they are admins
  const sortedCrewList = [...crewListToMap]?.sort((a, b) => {
    const isAdminA = adminIds?.includes(a.crew_id);
    const isAdminB = adminIds?.includes(b.crew_id);

    // Both are admins or both are not admins, maintain the original order
    if ((isAdminA && isAdminB) || (!isAdminA && !isAdminB)) {
      return 0;
    }
    // A is an admin but B is not, place A before B
    else if (isAdminA && !isAdminB) {
      return -1;
    }
    // B is an admin but A is not, place B before A
    else {
      return 1;
    }
  });

  const handleCrewClick = (crewId, e) => {
    e.stopPropagation();
    // Set a timeout for single-click detection
    const timeoutId = setTimeout(() => {
      setSelectedCrewId((prevCrewId) =>
        prevCrewId === crewId ? null : crewId
      );
    }, 200); // Delay for detecting single vs double-click
    setClickTimeout(timeoutId);
  };

  const handleCrewDoubleClick = (crewId, e) => {
    e.stopPropagation();
    // Clear the click timeout to avoid triggering onClick action
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
    // Navigate to the crew member's profile
    navigate(`/user/${userId}/profile-view/${crewId}`);
  };

  const crewDetail = (crew) => {
    return (
      <>
        <div className="crew-detail">
          <Avatar
            src={
              crew?.profile_picture_url === null ||
              crew?.profile_picture_url.endsWith("default.jpg")
                ? profileAvatar
                : crew?.profile_picture_url
            }
          />
          <div className="name-icons">
            <span className="name">{crew?.full_name}</span>
            <UserProfileDetailsComponent
              details={crew?.profile_details}
              userId={crew?.crew_id}
              isInCrewList={true}
            />
          </div>
        </div>

        <span className="captain">
          {adminIds?.includes(crew.crew_id) ? "Captain" : "Member"}
        </span>
      </>
    );
  };

  const items = sortedCrewList?.map((crew) => {
    const ifEveryChoiceIsNull = crew?.profile_details?.every(
      (detail) => detail[Object.keys(detail)[0]].choice === null
    );
    const isSelected = selectedCrewId === crew.crew_id;
    return {
      key: crew?.crew_id,
      label: (
        <>
          <div
            className={`crew ${ifEveryChoiceIsNull && "no-icons"}`}
            key={crew?.crew_id}
            onClick={(e) => handleCrewClick(crew?.crew_id, e)}
            onDoubleClick={(e) => handleCrewDoubleClick(crew?.crew_id, e)}
            style={{ userSelect: "none" }}
          >
            {crewDetail(crew)}
          </div>
          <div className={`crew-actions ${isSelected ? "show" : "hide"}`}>
            <Button type="primary" className="crew-btn">
              Message
            </Button>
            {adminIds?.includes(crew.crew_id) && (
              <Button className="crew-btn">Request to join</Button>
            )}
          </div>
        </>
      ),
    };
  });

  return (
    <Dropdown
      overlayClassName="crew-list-dropdown"
      trigger={["click"]}
      menu={{
        items,
      }}
      placement="bottom"
    >
      <div className="crew">
        <span>Crew Members</span>
        <img src={users} alt="users" />
      </div>
    </Dropdown>
  );
};

export default CrewListDropDown;
